import React, { Component } from 'react'
import Axios from 'axios'
import ReactTable from "react-table-v6"
import 'react-table-v6/react-table.css'

import {Button, Form, Modal} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Package extends Component {

  //DELETE FUNCTION
  delete = (id) => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL+'/packages/'+id,
    })
    .then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getPackageData()
    })
  }


    //Edit FUNCTION
    edit = (id) => {
      Axios({
        method: "get",
        url: process.env.REACT_APP_API_URL+'/packages/'+id,
      }).then(res => {
        this.setState({
          id: id,
          type: res.data[0].type,
          amount: res.data[0].amount,
          time: res.data[0].time,
          price: res.data[0].price
        })
      })
      this.handleShow()
    }

    eSubmit = (event, id) => {
      event.preventDefault()
      Axios({
        method: 'put',
        url: process.env.REACT_APP_API_URL+'/packages/'+this.state.id,
        data: {
          type: this.state.type,
          amount: this.state.amount,
          time: this.state.time,
          price: this.state.price

        }
      }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getPackageData()
    })
    this.handleClose()
  }
    handleClose = () => this.setState({show: false});
    handleShow = () => this.setState({show: true});

  // Submit Trigger
  submit = (event) => {
    event.preventDefault()
    Axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL+'/packages',
      data: {
        type: this.typeRef.current.value,
        amount: this.amountRef.current.value,
        time: this.timeRef.current.value,
        price: this.priceRef.current.value
      }
    }).then((res) => {
    if (res.data.error === false) {
      toast.success(res.data.message)
    } else {
      toast.error(res.data.message)
    }
    this.getPackageData()
  })
  }

  // Display Trigger
  async getPackageData(){
    const result = await Axios.get(process.env.REACT_APP_API_URL+'/packages');
    this.setState({data: result.data});
  }

  componentDidMount(){
    this.getPackageData()
  }

  // Filter Table
  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch()
    });
  };

  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return (
          value.type.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    this.setState({ filteredData });
  };

  constructor(props){
    super(props)
    this.state = {
      data: [],
      filteredData: [],
      searchInput: "",

      // Edit
      show: false,
      id: '',
      type: [''],
      amount: [''],
      time: [''],
      price: [''],
    }
    this.typeRef = React.createRef(null)
    this.amountRef = React.createRef(null)
    this.timeRef = React.createRef(null)
    this.priceRef = React.createRef(null)
  }

  render() {

    const columns = [
      { Header: 'Type', accessor: 'type' },
      { Header: 'Amount', accessor: 'amount' },
      { Header: 'Time', accessor: 'time' },
      { Header: 'Price', accessor: 'price' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Actions', accessor:'id',
        Cell: ({row}) => (
          <div>
             <FontAwesomeIcon className="text-danger mr-3" icon={faTrash} onClick={() => this.delete(row.id)} />
             <FontAwesomeIcon className="text-info ml-3" onClick={() => this.edit(row.id)} icon={faPencilAlt} />
          </div>
        )
      }
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
          <Form onSubmit={this.submit}>
            <div className="row">
            <div className="col-sm-6">
            <Form.Group controlId="type">
              <Form.Label>Type</Form.Label>
              <Form.Control as="select" ref={this.typeRef} required={true} >
                <option value="">-- Select --</option>
                <option value="1"> Single </option>
                <option value="2"> Multi </option>
              </Form.Control>
            </Form.Group>
            </div>
              <div className="col-sm-6">
                <Form.Group controlId="amount">
                  <Form.Label>Ad Amount</Form.Label>
                  <Form.Control type="text" ref={this.amountRef} placeholder="Amount" required={true} />
                </Form.Group>
              </div>

              <div className="col-sm-6">
                <Form.Group controlId="time">
                  <Form.Label>Time</Form.Label>
                  <Form.Control type="text" ref={this.timeRef} placeholder="Time" required={true} />
                </Form.Group>
              </div>

              <div className="col-sm-6">
                <Form.Group controlId="price">
                  <Form.Label>Price</Form.Label>
                  <Form.Control type="text" ref={this.priceRef} placeholder="Price" required={true} />
                </Form.Group>
              </div>

              <div className="col-sm-12">
                <Form.Group controlId="submit">
                <Form.Label>Submit</Form.Label>
                <Button variant="primary" type="submit" size="lg" block>
                  Submit
                </Button>
                </Form.Group>
              </div>
            </div>
          </Form>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <label>Search: </label>
              <input
                name="searchInput"
                onChange={this.handleChange}
                label="Search"
                className="ml-3"
              />
            </div>
            <ReactTable
               data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : this.state.data}
               columns={columns}
               defaultPageSize={10}
            />
          </div>
        </div>

        {/*here its edited modal */}

        <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Body>
              <Form onSubmit={this.eSubmit}>
                <div className="row">

                  <div className="col-sm-6">
                  <Form.Group controlId="type">
                    <Form.Label>Type</Form.Label>
                    <Form.Control as="select" onChange={(e) => this.setState({type: e.target.value})} value={this.state.type}  >
                      <option value="">-- Select --</option>
                      <option value="1"> Single </option>
                      <option value="2"> Multi </option>
                    </Form.Control>
                  </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="Price">
                      <Form.Label>Price</Form.Label>
                      <Form.Control type="text" onChange={(e) => this.setState({price: e.target.value})} value={this.state.price}  placeholder="Price"/>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="Time">
                      <Form.Label>Time</Form.Label>
                      <Form.Control type="text" onChange={(e) => this.setState({time: e.target.value})} value={this.state.time}  placeholder="Time"/>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="Amount">
                      <Form.Label>Ad Amount</Form.Label>
                      <Form.Control type="text" onChange={(e) => this.setState({amount: e.target.value})} value={this.state.amount}  placeholder="Amount"/>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="close">
                      <Form.Label>Close</Form.Label>
                      <Button variant="danger" type="button" size="lg" block onClick={this.handleClose}>
                        Close
                      </Button>
                    </Form.Group>
                  </div>
                </div>
              </Form>
            </Modal.Body>
        </Modal>
        <ToastContainer autoClose={3000} />

      </>
    );
  }
}
