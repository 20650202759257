import React, { Component } from "react";
import Axios from "axios";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

import { Button, Form, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class Model extends Component {
  //DELETE FUNCTION
  delete = id => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL + "/model/" + id
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getModelData();
    });
  };

  //Edit FUNCTION
  edit = id => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/model/" + id
    }).then(res => {
      console.log(res.data);
      this.setState({
        id: id,
        mkr_id: res.data[0].mkr_id,
        bdt_id: res.data[0].bdt_id,
        emaker: res.data[0].maker,
        ebodytype: res.data[0].bodytype,
        model: res.data[0].model
      });
    });
    this.handleShow();
  };

  eSubmit = (event, id) => {
    event.preventDefault();
    Axios({
      method: "put",
      url: process.env.REACT_APP_API_URL + "/model/" + this.state.id,
      data: {
        maker: this.state.mkr_id,
        bodytype: this.state.bdt_id,
        model: this.state.model
      }
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getModelData();
    });
    this.handleClose();
  };
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  // Submit Trigger
  submit = event => {
    event.preventDefault();
    Axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/model",
      data: {
        maker: this.makerRef.current.value,
        model: this.modelRef.current.value,
        bodytype: this.bodytypeRef.current.value
      }
    }).then(res => {
      if (res.data.errno) {
        toast.error("Not Submitted");
      } else {
        toast.success(res.data.message);
      }
      this.getModelData();
    });
  };

  // Display Trigger
  async getCategoryData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/category");
    this.setState({ category: result.data });
  }
  async getModelData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/model");
    this.setState({ data: result.data });
  }
  async getMakerData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/maker");
    this.setState({ maker: result.data });
  }
  async getMaker(id) {
    const result = await Axios.get(
      process.env.REACT_APP_API_URL + "/maker/category/" + id
    );
    this.setState({ maker: result.data });
  }
  async getSearchMaker(id) {
    const result = await Axios.get(
      process.env.REACT_APP_API_URL + "/maker/category/" + id
    );
    this.setState({ searchmaker: result.data });
  }
  async getBodyData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/bodytype");
    this.setState({ bodytype: result.data });
  }
  async getBody(id) {
    const result = await Axios.get(
      process.env.REACT_APP_API_URL + "/bodytype/category/" + id
    );
    this.setState({ bodytype: result.data });
  }

  getData(id) {
    this.getMaker(id);
    this.getBody(id);
  }

  getSearchData(value) {
    let data = value.split("_");
    this.getSearchMaker(data[data.length - 1]);
  }

  componentDidMount() {
    this.getCategoryData();
    this.getModelData();
    this.getMakerData();
    this.getBodyData();
  }

  // Filter Table
  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  handleChangeModel = () => {
    const split = this.categoryValRef.current.value.split("_");
    this.setState(
      { catInput: split[0], makerInput: this.makerValRef.current.value },
      () => {
        this.globalSearchModel();
      }
    );
  };

  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return (
        value.maker.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.model.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.status.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  globalSearchModel = () => {
    let { catInput, makerInput, data } = this.state;
    let filteredData = data.filter(value => {
      return makerInput
        ? value.category.toLowerCase() === catInput.toLowerCase() &&
            value.maker.toLowerCase() === makerInput.toLowerCase()
        : value.category.toLowerCase() === catInput.toLowerCase();
    });
    const nullData = [
      {
        id: "null",
        category: "null",
        maker: "null",
        model: "null",
        bodytype: "null",
        status: "null"
      }
    ];
    const searchData = filteredData.length ? filteredData : nullData;
    this.setState({ filteredData: searchData });
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      maker: [],
      category: [],
      bodytype: [],
      searchInput: "",
      searchmaker: [],
      catInput: "",
      makerInput: "",

      // Edit
      show: false,
      id: "",
      mkr_id: "",
      bdt_id: "",
      emaker: [],
      ebodytype: [],
      model: []
    };

    this.makerRef = React.createRef(null);
    this.modelRef = React.createRef(null);
    this.bodytypeRef = React.createRef(null);

    this.makerValRef = React.createRef(null);
    this.categoryValRef = React.createRef(null);
  }

  render() {
    const columns = [
      { Header: "Category", accessor: "category" },
      { Header: "Maker", accessor: "maker" },
      { Header: "Model", accessor: "model" },
      { Header: "Body Type", accessor: "name" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <FontAwesomeIcon
              className="text-danger mr-3"
              icon={faTrash}
              onClick={() => this.delete(row.id)}
            />
            <FontAwesomeIcon
              className="text-info ml-3"
              onClick={() => this.edit(row.id)}
              icon={faPencilAlt}
            />
          </div>
        )
      }
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
            <Form onSubmit={this.submit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="category">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => {
                        this.getData(e.target.value);
                      }}
                    >
                      <option value="">-- Select --</option>
                      {this.state.category.map(place => (
                        <option value={place.id}>{place.category}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="maker">
                    <Form.Label>Maker</Form.Label>
                    <Form.Control as="select" ref={this.makerRef}>
                      <option value="">-- Select --</option>
                      {this.state.maker.map(place => (
                        <option value={place.id}>{place.maker}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="model">
                    <Form.Label>Modal</Form.Label>
                    <Form.Control
                      type="text"
                      ref={this.modelRef}
                      placeholder="Code"
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="bodytype">
                    <Form.Label>Body Type</Form.Label>
                    <Form.Control as="select" ref={this.bodytypeRef}>
                      <option value="">-- Select --</option>
                      {this.state.bodytype.map(place => (
                        <option value={place.id}>{place.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <div className="row">
                <div className="col-sm-4">
                  <Form.Group controlId="category">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as="select"
                      ref={this.categoryValRef}
                      onChange={e => {
                        this.getSearchData(e.target.value);
                      }}
                    >
                      <option value="">-- Select --</option>
                      {this.state.category.map(place => (
                        <option value={`${place.category}_${place.id}`}>
                          {place.category}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-4">
                  <Form.Group controlId="maker">
                    <Form.Label>Maker</Form.Label>
                    <Form.Control as="select" ref={this.makerValRef}>
                      <option value="">-- Select --</option>
                      {this.state.searchmaker.map(place => (
                        <option value={place.maker}>{place.maker}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-4" onClick={this.handleChangeModel}>
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>
              </div>

              <label>Search: </label>
              <input
                name="searchInput"
                onChange={this.handleChange}
                label="Search"
                className="ml-3"
              />
            </div>
            <ReactTable
              data={
                this.state.filteredData && this.state.filteredData.length
                  ? this.state.filteredData
                  : this.state.data
              }
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </div>

        {/*here its edited modal */}
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Body>
            <Form onSubmit={this.eSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="Maker">
                    <Form.Label>Maker</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => this.setState({ mkr_id: e.target.value })}
                    >
                      <optgroup label="Selected">
                        <option value={this.state.mkr_id}>
                          {this.state.emaker}
                        </option>
                      </optgroup>
                      <optgroup label="Options">
                        {this.state.maker.map(emaker => (
                          <option value={emaker.id}>{emaker.maker}</option>
                        ))}
                      </optgroup>
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="Maker">
                    <Form.Label>Model</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => this.setState({ model: e.target.value })}
                      value={this.state.model}
                      placeholder="Model"
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="Bodytype">
                    <Form.Label>Body Type</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => this.setState({ bdt_id: e.target.value })}
                    >
                      <optgroup label="Selected">
                        <option value={this.state.bdt_id}>
                          {this.state.ebodytype}
                        </option>
                      </optgroup>
                      <optgroup label="Options">
                        {this.state.bodytype.map(ebodytype => (
                          <option value={ebodytype.id}>{ebodytype.name}</option>
                        ))}
                      </optgroup>
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="close">
                    <Form.Label>Close</Form.Label>
                    <Button
                      variant="danger"
                      type="button"
                      size="lg"
                      block
                      onClick={this.handleClose}
                    >
                      Close
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
