import React, { Component } from "react";
import Axios from "axios";

import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

import { Button, Form, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

export class Advertisment extends Component {
  //DELETE FUNCTION
  delete = (id) => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL + "/advertisment/" + id,
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getAdData();
    });
  };

  //Edit FUNCTION
  edit = (id) => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/advertisment/" + id,
    }).then((res) => {
      this.setState({
        id: id,
        page: res.data[0].page,
        link: res.data[0].link,
        views: res.data[0].views,
        alt: res.data[0].alt,
        code: res.data[0].code,
      });
    });
    this.handleShow();
  };

  eSubmit = (event, id) => {
    event.preventDefault();
    Axios({
      method: "put",
      url: process.env.REACT_APP_API_URL + "/advertisment/" + this.state.id,
      data: {
        page: this.state.page,
        link: this.state.link,
        views: this.state.views,
        alt: this.state.alt,
        code: this.state.code,
      },
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getAdData();
    });
    this.handleClose();
  };
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  // Submit Trigger
  submit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("page", this.pageRef.current.value);
    formData.append("view", this.viewRef.current.value);
    formData.append("link", this.linkRef.current.value);
    formData.append("alt", this.altRef.current.value);
    formData.append("code", this.codeRef.current.value);
    if (this.state.imageData.length !== 0) formData.append("image", this.state.imageData, this.state.imageData.name);

    Axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/advertisment",
      data: formData,
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getAdData();
    });
  };

  // Display Trigger
  async getAdData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/advertisment");
    this.setState({ data: result.data });
  }

  componentDidMount() {
    this.getAdData();
  }

  // Filter Table
  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };
  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter((value) => {
      return value.page.toLowerCase().includes(searchInput.toLowerCase()) || value.views.toLowerCase().includes(searchInput.toLowerCase());
    });
    this.setState({ filteredData });
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      searchInput: "",
      imageData: [],
      // Edit
      show: false,
      id: "",
      page: "",
      link: "",
      views: "",
      alt: "",
      code: "",
    };
    this.pageRef = React.createRef(null);
    this.viewRef = React.createRef(null);
    this.linkRef = React.createRef(null);
    this.imageRef = React.createRef(null);
    this.altRef = React.createRef(null);
    this.codeRef = React.createRef(null);
  }

  render() {
    const columns = [
      { Header: "Image", accessor: "image", Cell: ({ row }) => <img src={`${process.env.REACT_APP_AWS_URL}${row.image}`} height="30px" width="30px" /> },
      { Header: "Page", accessor: "page" },
      { Header: "Views", accessor: "views" },
      {
        Header: "Link",
        accessor: "link",
        Cell: ({ row }) => (
          <a href={row.link} target="_blank" rel="noopener noreferrer">
            Link
          </a>
        ),
      },
      { Header: "Alt", accessor: "alt" },
      { Header: "Code", accessor: "code" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <FontAwesomeIcon className="text-danger mr-3" icon={faTrash} onClick={() => this.delete(row.id)} />
            <FontAwesomeIcon className="text-info ml-3" onClick={() => this.edit(row.id)} icon={faPencilAlt} />
          </div>
        ),
      },
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
            <Form onSubmit={this.submit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="page">
                    <Form.Label>Page Name</Form.Label>
                    <Form.Control as="select" ref={this.pageRef}>
                      <option value="">--- Select ---</option>
                      <option>Footer Desktop</option>
                      <option>Footer Mobile</option>
                      <option>Home Desktop 1</option>
                      <option>Home Desktop 2</option>
                      <option>Home Desktop 3</option>
                      <option>Home Mobile 1</option>
                      <option>Home Mobile 2</option>
                      <option>Home Mobile 3</option>
                      <option>Listing Desktop 1</option>
                      <option>Listing Desktop 2</option>
                      <option>Listing Desktop 3</option>
                      <option>Listing Desktop 4</option>
                      <option>Listing Desktop 5</option>
                      <option>Listing Desktop 6</option>
                      <option>Listing Mobile 1</option>
                      <option>Listing Mobile 2</option>
                      <option>Listing Mobile 3</option>
                      <option>Listing Mobile 4</option>
                      <option>Listing Mobile 5</option>
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="views">
                    <Form.Label>Views</Form.Label>
                    <Form.Control as="select" ref={this.viewRef}>
                      <option value="">--- Select ---</option>
                      <option value="1">Horizontal</option>
                      <option value="2">Vertical</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group className="mb-3" controlId="link">
                    <Form.Label>Link</Form.Label>
                    <Form.Control type="text" placeholder="Link" ref={this.linkRef} />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="image" className="mb-3">
                    <Form.Label>Image</Form.Label>
                    <Form.File id="custom-file-translate-scss" label="Upload File" lang="en" custom onChange={(e) => this.setState({ imageData: e.target.files[0] })} />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group className="mb-3" controlId="alt">
                    <Form.Label>Alt</Form.Label>
                    <Form.Control type="text" placeholder="Alt" ref={this.altRef} />
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group className="mb-3" controlId="code">
                    <Form.Label>Code</Form.Label>
                    <Form.Control type="text" placeholder="Code" ref={this.codeRef} />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <label>Search: </label>
              <input name="searchInput" onChange={this.handleChange} label="Search" className="ml-3" />
            </div>
            <ReactTable data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : this.state.data} columns={columns} defaultPageSize={10} />
          </div>
        </div>

        {/*here its edited modal*/}
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Body>
            <Form onSubmit={this.eSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="page">
                    <Form.Label>Page Name</Form.Label>
                    <Form.Control as="select" onChange={(e) => this.setState({ page: e.target.value })}>
                      <option value="">--- Select ---</option>
                      <option value="1">Home</option>
                      <option value="2">About</option>
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="views">
                    <Form.Label>Views</Form.Label>
                    <Form.Control as="select" onChange={(e) => this.setState({ views: e.target.value })}>
                      <option value="">--- Select ---</option>
                      <option value="1" selected={this.state.views === "Horizontal" && "true"}>
                        Horizontal
                      </option>
                      <option value="2" selected={this.state.views === "Vertical" && "true"}>
                        Vertical
                      </option>
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="Link">
                    <Form.Label>Link</Form.Label>
                    <Form.Control type="text" onChange={(e) => this.setState({ link: e.target.value })} value={this.state.link} placeholder="Page Link" />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="alt">
                    <Form.Label>ALT</Form.Label>
                    <Form.Control type="text" onChange={(e) => this.setState({ alt: e.target.value })} value={this.state.alt} placeholder="Alt" />
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group controlId="code">
                    <Form.Label>Code</Form.Label>
                    <Form.Control type="text" onChange={(e) => this.setState({ code: e.target.value })} value={this.state.code} placeholder="Code" />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="close">
                    <Form.Label>Close</Form.Label>
                    <Button variant="danger" type="button" size="lg" block onClick={this.handleClose}>
                      Close
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
