import React, { Component } from "react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faCheck, faTimes, faLink } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

export class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      listing: [],
      categories: [],
      cities: [],
      status: "",
      category: "",
      type: "",
      city: "",
      date: "",
      offset: 0,
      limit: 50,
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  async approveAll() {
    var result = await Axios.get(process.env.REACT_APP_API_URL + "/listing/approve");
    this.getListingData();
  }

  async getUserData() {
    var result = await Axios.get(process.env.REACT_APP_API_URL + "/stat/user");
    this.setState({ data: result.data.total });

    this.getListingData();

    result = await Axios.get(process.env.REACT_APP_API_URL + "/category");
    this.setState({ categories: result.data });

    result = await Axios.get(process.env.REACT_APP_API_URL + "/city/country/7");
    this.setState({ cities: result.data });
  }

  getListingData = async () => {
    const { limit, offset, status, category, city, type, date } = this.state;
    const result = await Axios.post(
      `${process.env.REACT_APP_API_URL}/listing/category?limit=${limit}&offset=${offset}${city ? `&ce=${city}` : ""}${status ? (status == "Featured" ? `&featured=1` : `&status=${status}`) : ""}${
        category ? `&category=${category}` : ""
      }${type ? `&sp=${type}` : ""}&admin=true${date ? `&date=${date}` : ""}`
    );
    this.setState({ listing: result.data });
  };

  handleChange = async (event) => {
    const value = event.target.value;
    const { limit, offset, status, category, city, type, date } = this.state;
    const result = await Axios.post(
      `${process.env.REACT_APP_API_URL}/listing/category?limit=${limit}&offset=${offset}${value ? `&str=${value}` : ""}${city ? `&ce=${city}` : ""}${status ? `&status=${status}` : ""}${
        category ? `&category=${category}` : ""
      }${type ? `&sp=${type}` : ""}&admin=true${date ? `&date=${date}` : ""}`
    );
    this.setState({ listing: result.data });
  };

  status = (param, id) => {
    Axios.get(`${process.env.REACT_APP_API_URL}/listing/status?id=${id}&${param}`, { headers: { "x-access-token": localStorage.getItem("token") } }).then((res) => {
      if (res.data) {
        toast.success("Update successfully");
      } else {
        toast.warn("Update failed");
      }
      this.getListingData();
    });
  };

  urlMaker = (param) => {
    return param
      ? `${param
          .replace(/[ &\/\\#,+()@%^!$~%.'":*?<>{}]/g, "-")
          .replace(/-+/g, "-")
          .toLowerCase()}`
      : "";
  };

  render() {
    const { categories, cities, listing, data, offset } = this.state;

    return (
      <>
        <ToastContainer />
        <div className="row">
          {data &&
            data.map((item, i) => (
              <div className="col-sm-4 mb-4 my-3" key={i}>
                <div className="card">
                  <div className="card-body py-4">
                    <div className="d-flex align-items-start">
                      <div className="flex-grow-1">
                        <p className="mb-2">{item.label}</p>
                        <h3 className="mb-2">{item.total}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="row mb-3">
              <div className="col-sm-4 mb-4">
                <label>Search: </label>
                <input name="searchInput" onChange={this.handleChange} label="Search" className="form-control" />
              </div>
              <div className="col-sm-4 mb-4">
                <label>Status</label>
                <select className="form-control" onChange={(e) => this.setState({ status: e.target.value })}>
                  <option value="">Select status</option>
                  <option>Pending</option>
                  <option>Active</option>
                  <option>Deleted</option>
                  <option>Featured</option>
                </select>
              </div>
              <div className="col-sm-4 mb-4">
                <label>Category</label>
                <select className="form-control" onChange={(e) => this.setState({ category: e.target.value })}>
                  <option value="">Select category</option>
                  {categories &&
                    categories.map((category, i) => (
                      <option value={category.id}>
                        {category.category} ({category.total})
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-sm-4 mb-4">
                <label>Cities</label>
                <select className="form-control" onChange={(e) => this.setState({ city: e.target.value })}>
                  <option value="">Select City</option>
                  {cities &&
                    cities.map((city, i) => (
                      <option value={city.id}>
                        {city.city} ({city.total})
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-sm-4 mb-4">
                <label>Type</label>
                <select className="form-control" onChange={(e) => this.setState({ type: e.target.value })}>
                  <option value="">Select type</option>
                  <option>Dealer</option>
                  <option>Indiviual</option>
                </select>
              </div>
              <div className="col-sm-4 mb-4">
                <label>Date</label>
                <input type="date" name="date" className="form-control" onChange={(e) => this.setState({ date: e.target.value })} />
              </div>
              <div className="col-sm-4 mb-4">
                <label>Submit</label>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState(
                      {
                        offset: 0,
                      },
                      () => {
                        this.getListingData();
                      }
                    );
                  }}
                >
                  Search
                </button>
              </div>
              <div className="col-sm-4 mb-4">
                <label>Approve All</label>
                <button className="btn btn-danger w-100" onClick={() => this.approveAll()}>
                  Approve All
                </button>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Featured</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {listing &&
                    listing.map((item) => (
                      <tr>
                        <td>{item.id}</td>
                        <td>
                          {item.year} {item.maker} {item.model} {item.variation}
                        </td>
                        <td>{item.category}</td>
                        <td>{item.featured ? "Featured" : "NonFeatured"}</td>
                        <td>{item.status}</td>
                        <td>{moment(item.created_at).format("Do MMM YYYY")}</td>
                        <td>
                          <a href={`//autoones.com/used/listing/${item.year}-${this.urlMaker(item.maker)}-${this.urlMaker(item.model)}-${this.urlMaker(item.variation)}-${item.id}`} target="_blank">
                            <FontAwesomeIcon className="text-primary mr-3" icon={faLink} title="Link" />
                          </a>
                          <FontAwesomeIcon className="text-warning" icon={faDollarSign} title="Feature" onClick={() => this.status(`featured=${item.featured ? 0 : 1}`, item.id)} />
                          <FontAwesomeIcon className="text-success mx-3" icon={faCheck} title="Approve" onClick={() => this.status("status=2", item.id)} />
                          <FontAwesomeIcon className="text-danger" icon={faTimes} title="Reject" onClick={() => this.status("status=3", item.id)} />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-info cursor-pointer"
                  onClick={() => {
                    if (offset != 0) {
                      this.setState(
                        {
                          offset: offset - 1,
                        },
                        () => {
                          this.getListingData();
                        }
                      );
                    }
                  }}
                >
                  Prev
                </button>
                <button
                  onClick={() => {
                    this.setState(
                      {
                        offset: offset + 1,
                      },
                      () => {
                        this.getListingData();
                      }
                    );
                  }}
                  className="btn btn-info cursor-pointer"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
