import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

export function Error() {
  const [errors, setErrors] = useState();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/error`).then((res) => {
      const { data } = res;

      setErrors(data);
    });
  }, []);

  const columns = [
    { Header: "Url", accessor: "url" },
    { Header: "Error", accessor: "error" },
    {
      Header: "Created",
      accessor: "createdAt",
      Cell: ({ row }) =>
        moment(row.createdAt, "YYYY-MM-DD HH:mm:ss")
          .local()
          .format("Do MMM, YYYY"),
    },
  ];

  return (
    <div className="card mt-2">
      <div className="card-body">{errors && errors.length > 0 && <ReactTable data={errors} columns={columns} defaultPageSize={10} />}</div>
    </div>
  );
}
