import React, { Component } from "react";
import Axios from "axios";

export class Listing extends Component {
  async getListingData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/stat/listing");
    this.setState({ data: result.data.total });
  }

  componentDidMount() {
    this.getListingData();
  }

  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  render() {
    return (
      <>
        <div className="row">
          {this.state.data &&
            this.state.data.map((item, i) => (
              <div className="col-sm-4 my-3" key={i}>
                <div className="card">
                  <div class="card-body py-4">
                    <div class="d-flex align-items-start">
                      <div class="flex-grow-1">
                        <p class="mb-2">Total {item.label}</p>
                        <h3 class="mb-2">{item.total}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </>
    );
  }
}
