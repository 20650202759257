import React, { Component } from "react";
import Axios from "axios";

import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

import { Button, Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";

export class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      searchInput: "",
    };
  }

  componentDidMount() {
    this.getPaymentData();
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  // Display Trigger
  async getPaymentData() {
    const result = await Axios.get(
      process.env.REACT_APP_API_URL + "/as/sheets"
    );
    this.setState({ data: result.data });
  }

  // Filter Table
  handleChange = (event, status) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch(status);
    });
  };

  globalSearch = (status) => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter((value) => {
      return (
        value.id.toString().includes(searchInput.toLowerCase()) ||
        value.link?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.chassis?.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  render() {
    const columns = [
      { Header: "Id", accessor: "id" },
      {
        Header: "Link",
        accessor: "link",
        Cell: ({ row }) => (
          <a
            target="_blank"
            href={
              "https://autoones.com/auction-sheet/verified-auction-sheet/" +
              row._original.link
            }
          >
            Link
          </a>
        ),
      },
      { Header: "Chassis", accessor: "chassis" },
      { Header: "Sold", accessor: "total" },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ row }) =>
          moment(row._original.created_at).format("MMMM Do, YYYY / h:m a"),
      },
    ];

    return (
      <>
        <div className="card mt-2">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-3 form-group my-3 ml-3">
                <label>Search: </label>
                <input
                  name="searchInput"
                  className="form-control"
                  onChange={(e) => this.handleChange(e, null)}
                  label="Search"
                />
              </div>
            </div>
            <ReactTable
              data={
                this.state.filteredData && this.state.filteredData.length
                  ? this.state.filteredData
                  : this.state.data
              }
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </div>

        {/*here its edited modal */}
        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
