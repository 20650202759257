import React, { Component } from 'react'
import Axios from 'axios'
import ReactTable from "react-table-v6"
import 'react-table-v6/react-table.css'

import {Button, Form, Col, Modal} from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Country extends Component {

  //DELETE FUNCTION
  delete = (id) => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL+'/country/'+id,
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getCountryData()
    })
  }

  //Edit FUNCTION
  edit = (id) => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL+'/country/'+id,
    }).then(res => {
      this.setState({
        id: id,
        country: res.data[0].country,
        code: res.data[0].code
      })
    })
    this.handleShow()
  }

  eSubmit = (event, id) => {
    event.preventDefault()
    Axios({
      method: 'put',
      url: process.env.REACT_APP_API_URL+'/country/'+this.state.id,
      data: {
        country: this.state.country,
        code: this.state.code
      }
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getCountryData()
    })
  }
  handleClose = () => this.setState({show: false});
  handleShow = () => this.setState({show: true});

  // Submit Trigger
  submit = (event) => {
    event.preventDefault()
    Axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL+'/country',
      data: {
        country: this.countryRef.current.value,
        code: this.codeRef.current.value
      }
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getCountryData()
    })
  }

  // Display Trigger
  async getCountryData(){
    const result = await Axios.get(process.env.REACT_APP_API_URL+'/country');
    this.setState({data: result.data});
  }
  componentDidMount(){
    this.getCountryData()
  }

  // Filter Table
  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch()
    });
  };
  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return (
          value.country.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.code.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.status.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    this.setState({ filteredData });
  };

  constructor(props){
    super(props)
    this.state = {
      data: [],
      filteredData: [],
      searchInput: "",
      // Edit
      show: false,
      id: '',
      country: '',
      code: ''
    }
    this.countryRef = React.createRef(null)
    this.codeRef = React.createRef(null)
  }

  render() {

    const columns = [
      { Header: 'Country', accessor: 'country' },
      { Header: 'Code', accessor: 'code' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Actions', accessor:'id',
        Cell: ({row}) => (
          <div>
             <FontAwesomeIcon className="text-danger mr-3" icon={faTrash} onClick={() => this.delete(row.id)} />
             <FontAwesomeIcon className="text-info ml-3" onClick={() => this.edit(row.id)} icon={faPencilAlt} />
          </div>
        )
      }
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
          <Form onSubmit={this.submit}>
            <div className="row">
              <div className="col-sm-6">
                <Form.Group as={Col} controlId="country">
                  <Form.Label>Country</Form.Label>
                  <Form.Control type="text" ref={this.countryRef} placeholder="Enter Country" required={true} />
                </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group as={Col} controlId="code">
                  <Form.Label>Code</Form.Label>
                  <Form.Control type="text" ref={this.codeRef} placeholder="Code" required={true} />
                </Form.Group>
              </div>
              <div className="col-sm-12">
                <Form.Group as={Col} controlId="submit">
                <Form.Label>Submit</Form.Label>
                <Button variant="primary" type="submit" size="lg" block>
                  Submit
                </Button>
                </Form.Group>
              </div>
            </div>
          </Form>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <label>Search: </label>
              <input
                name="searchInput"
                onChange={this.handleChange}
                label="Search"
                className="ml-3"
              />
            </div>
            <ReactTable
               data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : this.state.data}
               columns={columns}
               defaultPageSize={10}
            />
          </div>
        </div>

            {/*here its edited modal*/}
            <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Body>
                  <Form onSubmit={this.eSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <Form.Group as={Col} controlId="country">
                          <Form.Label>Country</Form.Label>
                          <Form.Control type="text" onChange={(e) => this.setState({country: e.target.value})}  value={this.state.country} placeholder="Enter Country" required={true} />
                        </Form.Group>
                      </div>
                      <div className="col-sm-6">
                        <Form.Group as={Col} controlId="code">
                          <Form.Label>Code</Form.Label>
                          <Form.Control type="text" onChange={(e) => this.setState({code: e.target.value})} value={this.state.code} placeholder="Code" required={true} />
                        </Form.Group>
                      </div>
                      <div className="col-sm-6">
                        <Form.Group as={Col} controlId="submit">
                        <Form.Label>Submit</Form.Label>
                        <Button variant="primary" type="submit" size="lg" block>
                          Submit
                        </Button>
                        </Form.Group>
                      </div>

                      <div className="col-sm-6">
                        <Form.Group controlId="close">
                          <Form.Label>Close</Form.Label>
                          <Button variant="danger" type="button" size="lg" block onClick={this.handleClose}>
                            Close
                          </Button>
                        </Form.Group>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
            </Modal>
            <ToastContainer autoClose={3000} />
      </>
    );

  }

}
