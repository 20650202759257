import React, { Component } from "react";
import Axios from "axios";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

import { Button, Form, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import ChipInput from "material-ui-chip-input";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class Specifications extends Component {
  //DELETE FUNCTION
  delete = id => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL + "/specifications/" + id
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getSpecificationsData();
    });
  };

  //Edit FUNCTION
  edit = id => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/specifications/" + id
    }).then(res => {
      this.setState({
        id: id,
        cto_id: res.data[0].cto_id,
        gp_id: res.data[0].gp_id,
        ecategory: res.data[0].category,
        egroups: res.data[0].gcategory,
        specifications: res.data[0].specifications
      });
    });
    this.handleShow();
  };

  eSubmit = (event, id) => {
    event.preventDefault();
    Axios({
      method: "put",
      url: process.env.REACT_APP_API_URL + "/specifications/" + this.state.id,
      data: {
        category: this.state.cto_id,
        groups: this.state.gp_id,
        specifications: this.state.specifications
      }
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getSpecificationsData();
    });
    this.handleClose();
  };
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  // Submit Trigger
  submit = event => {
    event.preventDefault();
    Axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/specifications",
      data: {
        specifications: this.specificationsRef.current.value,
        category: this.categoryRef.current.value,
        groups: this.groupsRef.current.value,
        options: this.optionsRef.current
          ? this.optionsRef.current.state.chips
          : ""
      }
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getSpecificationsData();
    });
  };

  // Display Trigger
  async getCategoryData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/category");
    this.setState({ category: result.data });
  }
  async getSpecificationsData() {
    const result = await Axios.get(
      process.env.REACT_APP_API_URL + "/specifications"
    );
    this.setState({ data: result.data });
  }
  async getGroupsData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/groups");
    this.setState({ groups: result.data });
  }

  componentDidMount() {
    this.getSpecificationsData();
    this.getCategoryData();
    this.getGroupsData();
  }

  // Filter Table
  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return (
        value.category.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.specifications
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        value.status.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.groups?.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  handleChangeSpecs = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearchSpecs();
    });
  };

  globalSearchSpecs = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return value.category.toLowerCase().includes(searchInput.toLowerCase());
    });
    const nullData = [
      {
        id: "null",
        category: "null",
        features: "null",
        group: "null",
        specifications: "null",
        status: "null"
      }
    ];
    const searchData = filteredData.length ? filteredData : nullData;
    this.setState({ filteredData: searchData });
  };

  constructor(props) {
    super(props);
    this.state = {
      imageData: [],
      data: [],
      category: [],
      groups: [],
      filteredData: [],
      specifications: [],
      searchInput: "",
      selectCheck: false,
      // Edit
      show: false,
      id: "",
      cto_id: "",
      gp_id: "",
      ecategory: [],
      egroups: [],
      specifications: []
    };
    this.categoryRef = React.createRef(null);
    this.groupsRef = React.createRef(null);
    this.specificationsRef = React.createRef(null);
    this.optionsRef = React.createRef(null);
  }

  selectCheck = event => {
    if (event.target.checked) {
      this.setState({ selectCheck: true });
    } else {
      this.setState({ selectCheck: false });
    }
  };

  render() {
    const columns = [
      { Header: "Specifications", accessor: "specifications" },
      { Header: "Category", accessor: "category" },
      { Header: "Groups", accessor: "gcategory" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <FontAwesomeIcon
              className="text-danger mr-3"
              icon={faTrash}
              onClick={() => this.delete(row.id)}
            />
            <FontAwesomeIcon
              className="text-info ml-3"
              onClick={() => this.edit(row.id)}
              icon={faPencilAlt}
            />
          </div>
        )
      }
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
            <Form onSubmit={this.submit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="category">
                    <Form.Label>Category</Form.Label>
                    <Form.Control as="select" ref={this.categoryRef}>
                      <option value="">-- Select --</option>
                      {this.state.category.map(place => (
                        <option value={place.id}>{place.category}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group controlId="groups">
                    <Form.Label>Groups</Form.Label>
                    <Form.Control as="select" ref={this.groupsRef}>
                      <option value="">-- Select --</option>
                      {this.state.groups.map(place => (
                        <option value={place.id}>{place.category}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="city">
                    <Form.Label>Specifications</Form.Label>
                    <Form.Control
                      type="text"
                      ref={this.specificationsRef}
                      placeholder="Specifications"
                    />
                    <Form.Check
                      type="checkbox"
                      onChange={event => this.selectCheck(event)}
                      label="Add Select Option"
                    />
                  </Form.Group>
                </div>

                {this.state.selectCheck && (
                  <div className="col-sm-6">
                    <Form.Group controlId="advance">
                      <Form.Label>Select Options</Form.Label>
                      <ChipInput
                        className="form-control"
                        ref={this.optionsRef}
                      />
                    </Form.Group>
                  </div>
                )}

                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <div className="col-sm-6" onChange={this.handleChangeSpecs}>
                <Form.Group controlId="Body category">
                  <Form.Label>Category</Form.Label>
                  <Form.Control as="select">
                    <option value="">-- Select --</option>
                    {this.state.category.map(place => (
                      <option value={place.category}>{place.category}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <label>Search: </label>
              <input
                name="searchInput"
                onChange={this.handleChange}
                label="Search"
                className="ml-3"
              />
            </div>
            <ReactTable
              data={
                this.state.filteredData && this.state.filteredData.length
                  ? this.state.filteredData
                  : this.state.data
              }
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </div>

        {/*here its edited modal */}
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Body>
            <Form onSubmit={this.eSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="Category">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => this.setState({ cto_id: e.target.value })}
                    >
                      <optgroup label="Selected">
                        <option value={this.state.cto_id}>
                          {this.state.ecategory}
                        </option>
                      </optgroup>
                      <optgroup label="Options">
                        {this.state.category.map(ecategory => (
                          <option value={ecategory.id}>
                            {ecategory.category}
                          </option>
                        ))}
                      </optgroup>
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="Groups">
                    <Form.Label>Groups</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => this.setState({ gp_id: e.target.value })}
                    >
                      <optgroup label="Selected">
                        <option value={this.state.gp_id}>
                          {this.state.egroups}
                        </option>
                      </optgroup>
                      <optgroup label="Options">
                        {this.state.groups.map(group => (
                          <option value={group.id}>{group.category}</option>
                        ))}
                      </optgroup>
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="Specifications">
                    <Form.Label>Specifications</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e =>
                        this.setState({ specifications: e.target.value })
                      }
                      value={this.state.specifications}
                      placeholder="City"
                    />
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="close">
                    <Form.Label>Close</Form.Label>
                    <Button
                      variant="danger"
                      type="button"
                      size="lg"
                      block
                      onClick={this.handleClose}
                    >
                      Close
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
