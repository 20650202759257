import Axios from "axios";
import { Link } from "react-router-dom";
import { Row, Col, Container, Form } from "react-bootstrap";
import React, { useRef, useState, useEffect } from "react";

const RegForm = () => {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const usernameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const passRef = useRef();
  const confirmRef = useRef();
  const termsConditionsRef = useRef();
  const showRoomRef = useRef();
  const addressRef = useRef();
  const cityRef = useRef();
  const makerRef = useRef();

  const [city, setCity] = useState([]);
  const [maker, setMaker] = useState([]);
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL}/city/country/7`).then(res => {
      const { data } = res;

      setCity(data);
    });

    Axios.get(`${process.env.REACT_APP_API_URL}/maker/category/1`).then(res => {
      const { data } = res;

      setMaker(data);
    });
  }, []);

  /* States */
  const [showModal, setShowModal] = useState(false);
  const [dealer, setDealer] = useState(true);
  const verifyDiv = useRef();

  const submit = async e => {
    e.preventDefault();

    var check = 0;
    /* Form Validators - Empty Check */
    firstNameRef.current.value.length === 0 && check++;
    lastNameRef.current.value.length === 0 && check++;
    usernameRef.current.value.length === 0 && check++;
    emailRef.current.value.length === 0 && check++;
    passRef.current.value.length === 0 && check++;
    if (dealer) {
      showRoomRef.current.value.length === 0 && check++;
      addressRef.current.value.length === 0 && check++;
    }
    /* Form Validators - Custom Check */
    (await verifyUsername(usernameRef.current.value)) && check++;
    (await verifyEmail(emailRef.current.value)) && check++;
    (await matchPass(passRef.current.value, confirmRef.current.value)) &&
      check++;

    if (check === 0) {
      /* Check if Terms & Conditions are checked or not */
      const formData = {
        name: `${firstNameRef.current.value} ${lastNameRef.current.value}`,
        username: usernameRef.current.value,
        email: emailRef.current.value,
        phone: phoneRef.current.value,
        pass: passRef.current.value,
        type: 2
      };
      if (dealer) {
        formData["showroom"] = showRoomRef.current.value;
        formData["address"] = addressRef.current.value;
        formData["maker"] = makerRef.current.value;
        formData["city"] = cityRef.current.value;
      }
      const result = await Axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register/`,
        formData
      );

      /* Verify User Account */
      if (result.data.id) {
        setShowModal(true);
      }
    }
  };

  return (
    <div id="register">
      <Container fluid>
        <form onSubmit={submit} autoComplete="off">
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="fname">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  ref={firstNameRef}
                  type="text"
                  className="custom-formControl"
                  placeholder="First Name"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="lname">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  ref={lastNameRef}
                  type="text"
                  className="custom-formControl"
                  placeholder="Last Name"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>
                  Username
                  <span
                    id="username-error"
                    className="text-danger"
                    style={{ fontSize: "0.7rem" }}
                  ></span>
                  <span
                    id="username-success"
                    className="text-success"
                    style={{ fontSize: "0.7rem" }}
                  ></span>
                </Form.Label>
                <Form.Control
                  ref={usernameRef}
                  onChange={e => verifyUsername(e.target.value)}
                  type="text"
                  className="custom-formControl"
                  placeholder="Username"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>City</Form.Label>
                <Form.Control
                  ref={cityRef}
                  as="select"
                  className="custom-formControl"
                >
                  {city.map(item => (
                    <option value={item.id}>{item.city}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>Maker</Form.Label>
                <Form.Control
                  ref={makerRef}
                  as="select"
                  className="custom-formControl"
                >
                  {maker.map(item => (
                    <option value={item.id}>{item.maker}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            {dealer && (
              <>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="fname">
                    <Form.Label>Showroom Name</Form.Label>
                    <Form.Control
                      ref={showRoomRef}
                      type="text"
                      className="custom-formControl"
                      placeholder="Showroom name"
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="lname">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      ref={addressRef}
                      type="text"
                      className="custom-formControl"
                      placeholder="Address"
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="lname">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      ref={phoneRef}
                      type="text"
                      className="custom-formControl"
                      placeholder="Phone"
                    />
                  </Form.Group>
                </Col>
              </>
            )}
            <Col md={6}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>
                  Email
                  <span
                    id="email-error"
                    className="text-danger"
                    style={{ fontSize: "0.7rem" }}
                  ></span>
                  <span
                    id="email-success"
                    className="text-success"
                    style={{ fontSize: "0.7rem" }}
                  ></span>
                </Form.Label>
                <Form.Control
                  ref={emailRef}
                  onChange={e => verifyEmail(e.target.value)}
                  type="text"
                  className="custom-formControl"
                  placeholder="Email"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  ref={passRef}
                  type="password"
                  className="custom-formControl"
                  placeholder="Password"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="confirm">
                <Form.Label>
                  Confirm Password
                  <span
                    id="confirm-error"
                    className="text-danger"
                    style={{ fontSize: "0.7rem" }}
                  ></span>
                </Form.Label>
                <Form.Control
                  ref={confirmRef}
                  onChange={e =>
                    matchPass(e.target.value, passRef.current.value)
                  }
                  type="password"
                  className="custom-formControl"
                  placeholder="Confirm Password"
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <span
                id="error-msgs"
                className="text-danger"
                style={{ fontSize: "0.8rem" }}
              ></span>
              <Row>
                <Col sm={6}>
                  <button type="submit" className="btn btn-danger m-1">
                    {showModal ? `Created` : "Create"}
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
};

/* Function to verify username */
const verifyUsername = async username => {
  var usernameRegex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/;
  if (usernameRegex.test(username)) {
    document.getElementById("username-error").innerHTML = "";

    /* Verify, if the username is duplicate or not */
    const result = await duplicate("username", username);
    if (result.data.data) {
      document.getElementById("username-success").innerHTML = "";
      document.getElementById("username-error").innerHTML =
        " Username Unavailable";
      return true;
    } else {
      document.getElementById("username-error").innerHTML = "";
      document.getElementById("username-success").innerHTML =
        " Username Available";
      return false;
    }
  } else {
    document.getElementById("username-error").innerHTML =
      " Invalid Username Format";
    document.getElementById("username-success").innerHTML = "";
    return true;
  }
};

/* Function to verify email */
const verifyEmail = async email => {
  var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRegex.test(email)) {
    document.getElementById("email-error").innerHTML = "";

    /* Verify, if the email is duplicate or not */
    const result = await duplicate("email", email);
    if (result.data.data) {
      document.getElementById("email-error").innerHTML = " Email Unavailable";
      document.getElementById("email-success").innerHTML = "";
      return true;
    } else {
      document.getElementById("email-error").innerHTML = "";
      document.getElementById("email-success").innerHTML = " Email Available";
      return false;
    }
  } else {
    document.getElementById("email-error").innerHTML = " Invalid Email Format";
    document.getElementById("email-success").innerHTML = "";
    return true;
  }
};

/* Function to match password */
const matchPass = (confirm, password) => {
  if (confirm.length != 0 || password.length != 0) {
    if (password === confirm) {
      document.getElementById("confirm-error").innerHTML = "";
      return false;
    } else {
      document.getElementById("confirm-error").innerHTML =
        " Password doesn't match";
      return true;
    }
  } else {
    return true;
  }
};

/* Duplicate Checker Function */
const duplicate = async (cell, value) => {
  const result = await Axios.post(
    `${process.env.REACT_APP_API_URL}/auth/duplicate`,
    { cell: cell, value: value }
  );
  console.log(result);
  return result;
};

export default RegForm;
