import React, { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const FuelPrices = () => {
  const [id, setId] = useState();
  const [prices, setPrices] = useState([]);
  const [maker, setMaker] = useState();
  const [makers, setMakers] = useState();
  const [model, setModel] = useState();
  const [models, setModels] = useState();
  const [variant, setVariant] = useState();
  const [variants, setVariants] = useState();
  const [longMileage, setLongMileage] = useState();
  const [cityMileage, setCityMileage] = useState();

  useEffect(() => {
    getMaker(1);
    getPrices();
  }, []);

  const getPrices = () => {
    Axios.get(`${process.env.REACT_APP_API_URL}/mileage-calculator`).then((res) => {
      setPrices(res.data);
    });
  };

  const submit = (event) => {
    event.preventDefault();

    if (id) {
      const data = { maker, model, variant, longMileage, cityMileage };
      Axios.put(`${process.env.REACT_APP_API_URL}/mileage-calculator/${id}`, data).then((res) => {
        getPrices();
        setId();
      });
    } else {
      const data = { maker, model, variant, longMileage, cityMileage };
      Axios.post(`${process.env.REACT_APP_API_URL}/mileage-calculator`, data).then((res) => {
        getPrices();
      });
    }
  };

  const deletePrice = (id) => {
    Axios.delete(`${process.env.REACT_APP_API_URL}/mileage-calculator/${id}`).then((res) => {
      getPrices();
    });
  };

  const editPrice = (price) => {
    setId(price.id);
    setMaker(price.makerId);
    setModel(price.modelId);
    setVariant(price.variantId);
    setLongMileage(price.longMileage);
    setCityMileage(price.cityMileage);
  };

  const getMaker = async (id) => {
    var result = await Axios.get(process.env.REACT_APP_API_URL + "/maker/category/" + id);
    setMakers(result.data);
  };
  const getModel = async (id) => {
    setMaker(id);
    var result = await Axios.get(process.env.REACT_APP_API_URL + "/model/maker/" + id);
    setModels(result.data);
  };
  const getVariant = async (id) => {
    setModel(id);
    var result = await Axios.get(process.env.REACT_APP_API_URL + "/variation/model/" + id);
    setVariants(result.data);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body bg-white">
              <form onSubmit={submit}>
                <div className="form-group">
                  <label>Maker</label>
                  <select required onChange={(e) => getModel(e.target.value)} value={maker} className="form-control">
                    <option></option>
                    {makers &&
                      makers.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.maker}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Model</label>
                  <select required onChange={(e) => getVariant(e.target.value)} value={model} className="form-control">
                    <option></option>
                    {models &&
                      models.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.model}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Variant</label>
                  <select required onChange={(e) => setVariant(e.target.value)} value={variant} className="form-control">
                    <option></option>
                    {variants &&
                      variants.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>City Mileage</label>
                  <input required value={cityMileage} onChange={(e) => setCityMileage(e.target.value)} name="cityMileage" type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label>Long Mileage</label>
                  <input required value={longMileage} onChange={(e) => setLongMileage(e.target.value)} name="longMileage" type="text" className="form-control" />
                </div>
                <button className="btn btn-light" type="submit">
                  {id ? "Update" : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="col-sm-8">
          <div className="card">
            <div className="card-body bg-white">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Maker</th>
                    <th>Model</th>
                    <th>Variant</th>
                    <th>Long Mileage</th>
                    <th>City Mileage</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {prices.map((price, i) => (
                    <tr>
                      <td>{price.maker}</td>
                      <td>{price.model}</td>
                      <td>{price.variant}</td>
                      <td>{price.longMileage}</td>
                      <td>{price.cityMileage}</td>
                      <td>
                        <i className="mx-3 text-danger" onClick={() => deletePrice(price.id)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </i>
                        <i className="mx-3 text-info" onClick={() => editPrice(price)}>
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FuelPrices;
