import React, { Component } from "react";
import Axios from "axios";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

import { Button, Form, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class ASPackage extends Component {
  //DELETE FUNCTION
  delete = (id) => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL + "/packages/auctionsheet/" + id,
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getPackageData();
    });
  };

  //Edit FUNCTION
  edit = (id) => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/packages/auctionsheet/" + id,
    }).then((res) => {
      this.setState({
        id: id,
        name: res.data[0].name,
        sheets: res.data[0].sheets,
        price: res.data[0].price,
      });
    });
    this.handleShow();
  };

  eSubmit = (event, id) => {
    event.preventDefault();
    Axios({
      method: "put",
      url: process.env.REACT_APP_API_URL + "/packages/auctionsheet/" + this.state.id,
      data: {
        name: this.state.name,
        sheets: this.state.sheets,
        price: this.state.price,
      },
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getPackageData();
    });
    this.handleClose();
  };
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  // Submit Trigger
  submit = (event) => {
    event.preventDefault();
    Axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/packages/auctionsheet",
      data: {
        name: this.nameRef.current.value,
        sheets: this.sheetsRef.current.value,
        price: this.priceRef.current.value,
      },
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getPackageData();
    });
  };

  // Display Trigger
  async getPackageData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/packages/auctionsheet");
    this.setState({ data: result.data });
  }

  componentDidMount() {
    this.getPackageData();
  }

  // Filter Table
  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter((value) => {
      return value.type.toLowerCase().includes(searchInput.toLowerCase());
    });
    this.setState({ filteredData });
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      searchInput: "",

      // Edit
      show: false,
      id: "",
      name: [""],
      sheets: [""],
      price: [""],
    };
    this.nameRef = React.createRef(null);
    this.sheetsRef = React.createRef(null);
    this.priceRef = React.createRef(null);
  }

  render() {
    const columns = [
      { Header: "Name", accessor: "name" },
      { Header: "Sheets", accessor: "sheets" },
      { Header: "Price", accessor: "price" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <FontAwesomeIcon className="text-danger mr-3" icon={faTrash} onClick={() => this.delete(row.id)} />
            <FontAwesomeIcon className="text-info ml-3" onClick={() => this.edit(row.id)} icon={faPencilAlt} />
          </div>
        ),
      },
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
            <Form onSubmit={this.submit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="name">
                    <Form.Label>Ad Name</Form.Label>
                    <Form.Control type="text" ref={this.nameRef} placeholder="Name" required={true} />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="sheets">
                    <Form.Label>Sheets</Form.Label>
                    <Form.Control type="text" ref={this.sheetsRef} placeholder="Sheets" required={true} />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="price">
                    <Form.Label>Price</Form.Label>
                    <Form.Control type="text" ref={this.priceRef} placeholder="Price" required={true} />
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <label>Search: </label>
              <input name="searchInput" onChange={this.handleChange} label="Search" className="ml-3" />
            </div>
            <ReactTable data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : this.state.data} columns={columns} defaultPageSize={10} />
          </div>
        </div>

        {/*here its edited modal */}

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Body>
            <Form onSubmit={this.eSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="Price">
                    <Form.Label>Price</Form.Label>
                    <Form.Control type="text" onChange={(e) => this.setState({ price: e.target.value })} value={this.state.price} placeholder="Price" />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="Sheets">
                    <Form.Label>Sheets</Form.Label>
                    <Form.Control type="text" onChange={(e) => this.setState({ sheets: e.target.value })} value={this.state.sheets} placeholder="Sheets" />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="Name">
                    <Form.Label>Ad Name</Form.Label>
                    <Form.Control type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} placeholder="Name" />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="close">
                    <Form.Label>Close</Form.Label>
                    <Button variant="danger" type="button" size="lg" block onClick={this.handleClose}>
                      Close
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
