import React, { Component } from 'react'
import Axios from 'axios'
import ReactTable from "react-table-v6"
import 'react-table-v6/react-table.css'

import Select from 'react-select'

import { Editor } from '@tinymce/tinymce-react';

import RUG, { DragArea } from "react-upload-gallery";
import "react-upload-gallery/dist/style.css";

import {Button, Form} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Addproduct extends Component {

  handleEditorChange = (e) => {
    this.setState({description: e.target.getContent()})
  }
  // Submit Trigger
  submit = (event) => {
    event.preventDefault()

    let formData = new FormData()
    if (this.state.images.length !== 0) {
      for (var i = 0; i < this.state.images.length; i++) {
        formData.append('images[]', this.state.images[i]['file'], this.state.images[i]['file']['image'])
      }
    }
    formData.append('category', this.state.categoryValue)
    formData.append('subcategory', this.state.subCategoryValue)
    formData.append('price', this.priceRef.current.value)
    formData.append('title', this.titleRef.current.value)
    formData.append('discount', this.discountRef.current.value)
    formData.append('shipping', this.shippingRef.current.value)
    formData.append('quantity', this.quantityRef.current.value)
    formData.append('description', this.state.description)

    Axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL+'/product',
      data: formData
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
    })

  }

  getCategories = () => {
    Axios.get(`${process.env.REACT_APP_API_URL}/shopcategory`)
    .then(res => {
      let categoryOption = []
      res.data.map(item => {
        categoryOption.push({label: item.shopcategory, value: item.id})
      })
      this.setState({
        category: categoryOption,
      })
    })

    Axios.get(`${process.env.REACT_APP_API_URL}/subcategory`)
    .then(res => {
      let subcategoryOption = []
      res.data.map(item => {
        subcategoryOption.push({label: item.subcategory, value: item.id})
      })
      this.setState({
        subcategory: subcategoryOption
      })
    })
  }

  componentDidMount(){
    this.getCategories()
  }

  constructor(props){
    super(props)
    this.state = {
      data: [],
      filteredData: [],
      images: [],
      searchInput: "",
      category: [],
      subcategory: [],
      categoryValue: '',
      subCategoryValue: ''
    }
    this.categoryRef = React.createRef(null)
    this.subcategoryRef = React.createRef(null)
    this.titleRef = React.createRef(null)
    this.priceRef = React.createRef(null)
    this.descriptionRef = React.createRef(null)
    this.discountRef = React.createRef(null)
    this.shippingRef = React.createRef(null)
    this.quantityRef = React.createRef(null)
  }

  render() {

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
          <Form onSubmit={this.submit}>
            <div className="row">
              <div className="col-sm-6">
                <Form.Group controlId="price">
                  <Form.Label>Category</Form.Label>
                  <Select onChange={(e) => this.setState( {categoryValue: e.value} )}
                    options={this.state.category}></Select>
                </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group controlId="price">
                  <Form.Label>SubCategory</Form.Label>
                  <Select  onChange={(e) => this.setState( {subCategoryValue: e.value} )}
                    options={this.state.subcategory}></Select>
                </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group controlId="price">
                  <Form.Label>Price</Form.Label>
                  <Form.Control type="text" ref={this.priceRef} required={true} />
                </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group controlId="title">
                  <Form.Label>Title</Form.Label>
                  <Form.Control type="text" ref={this.titleRef} required={true} />
                </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group controlId="discount">
                  <Form.Label>Discount</Form.Label>
                  <Form.Control type="text" ref={this.discountRef} required={true} />
                </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group controlId="shipping">
                  <Form.Label>Shipping</Form.Label>
                  <Form.Control type="text" ref={this.shippingRef} required={true} />
                </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group controlId="quantity">
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control type="text" ref={this.quantityRef} required={true} />
                </Form.Group>
              </div>
              <div className="col-sm-12">
              <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <Editor
                  apiKey="zpa6jhahb7wr51wcc4yrbt91xeuizav1kudmrtpziohibpz4"
                  init={{
                    plugins: 'link image code',
                    height: 500,
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                  }}
                  onChange={this.handleEditorChange}
                />
              </Form.Group>
              </div>
              <div className="col-sm-12 mt-3">
                  <Form.Label htmlFor="basic-url">Images</Form.Label>
                  <RUG
                    accept={['jpg', 'jpeg', 'png']}
                    rules={{size: 5000}}
                    autoUpload="false"
                    onChange={(images) => {
                      this.setState({ images })
                    }}
                  />
              </div>
              <div className="col-sm-6">
                <Form.Group controlId="submit">
                <Form.Label>Submit</Form.Label>
                <Button variant="primary" type="submit" size="lg" block>
                  Submit
                </Button>
                </Form.Group>
              </div>

            </div>
          </Form>
          </div>
        </div>

        <ToastContainer autoClose={3000} />

      </>
    );
  }
}
