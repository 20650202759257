import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


export function Featured() {

  return (
    <>
      <div className="card mt-2">
        <div className="card-body">

        </div>
      </div>
    </>
  )
}
