import React, { Component } from "react";
import Axios from "axios";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Form, Col, Modal } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt, faCheck, faTimes, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      searchInput: "",
      // Edit
      show: false,
      id: "",
      stat: [],
      price: [],
      title: [],
      discount: [],
      shipping: [],
      quantity: [],
      description: [],
    };
  }

  componentDidMount() {
    this.getProductData();
  }

  //DELETE FUNCTION
  delete = (id) => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL + "/product/" + id,
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getProductData();
    });
  };

  status = async (param, id) => {
    Axios.get(`${process.env.REACT_APP_API_URL}/product/status?id=${id}&${param}`, { headers: { "x-access-token": localStorage.getItem("token") } }).then((res) => {
      if (res.data) {
        toast.success("Update successfully");
      } else {
        toast.warn("Update failed");
      }
      this.getProductData();
    });
  };

  //Edit FUNCTION
  edit = (id) => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/product/" + id,
    }).then((res) => {
      this.setState({
        id: id,
        price: res.data[0].price,
        title: res.data[0].title,
        description: res.data[0].description,
        discount: res.data[0].discount,
        shipping: res.data[0].shipping,
        quantity: res.data[0].quantity,
      });
    });
    this.handleShow();
  };

  eSubmit = (event, id) => {
    event.preventDefault();
    Axios({
      method: "put",
      url: process.env.REACT_APP_API_URL + "/product/" + this.state.id,
      data: {
        price: this.state.price,
        title: this.state.title,
        discount: this.state.discount,
        shipping: this.state.shipping,
        description: this.state.description,
        quantity: this.state.quantity,
      },
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getProductData();
    });
    this.handleClose();
  };
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  async getProductData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/product?admin=true");
    this.setState({ data: result.data.products });

    Axios.get(process.env.REACT_APP_API_URL + "/stat/product").then((res) => {
      this.setState({ stat: res.data.total });
    });
  }

  // Filter Table
  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter((value) => {
      return (
        value.price
          ?.toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        value.title.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.status.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  render() {
    const { stat } = this.state;

    const columns = [
      { Header: "Tile", accessor: "title" },
      { Header: "Price", accessor: "price" },
      { Header: "Description", accessor: "description" },
      {
        Header: "Featued",
        accessor: "featured",
        Cell: ({ row }) => (row._original.featured == 1 ? `Featured` : `NonFeatured`),
      },
      { Header: "Status", accessor: "status" },
      {
        Header: "Posted",
        accessor: "created_at",
        Cell: ({ row }) => moment(row._original.created_at).format("Do, MMM YYYY"),
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <FontAwesomeIcon className="text-danger" icon={faTrash} onClick={() => this.delete(row.id)} />
            <FontAwesomeIcon className="text-info mx-3" icon={faPencilAlt} onClick={() => this.edit(row.id)} />
            <FontAwesomeIcon className="text-warning" icon={faDollarSign} title="Feature" onClick={() => this.status(`featured=${row._original.featured ? 0 : 1}`, row.id)} />
            <FontAwesomeIcon className="text-success mx-3" icon={faCheck} title="Approve" onClick={() => this.status("status=2", row.id)} />
            <FontAwesomeIcon className="text-danger" icon={faTimes} title="Reject" onClick={() => this.status("status=3", row.id)} />
          </div>
        ),
      },
    ];

    return (
      <>
        <div className="row">
          {stat &&
            stat.map((item, i) => (
              <div className="col-sm-4 mb-4 my-3" key={i}>
                <div className="card">
                  <div className="card-body py-4">
                    <div className="d-flex align-items-start">
                      <div className="flex-grow-1">
                        <p className="mb-2">{item.label}</p>
                        <h3 className="mb-2">{item.total}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <label>Search: </label>
              <input name="searchInput" onChange={this.handleChange} label="Search" className="ml-3" />
            </div>
            <ReactTable data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : this.state.data} columns={columns} defaultPageSize={10} />
          </div>
        </div>

        {/*here its edited modal */}
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Body>
            <Form onSubmit={this.eSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="price">
                    <Form.Label>Price</Form.Label>
                    <Form.Control type="text" onChange={(e) => this.setState({ price: e.target.value })} value={this.state.price} placeholder="Price" />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="title">
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" onChange={(e) => this.setState({ title: e.target.value })} value={this.state.title} placeholder="Title" />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="discount">
                    <Form.Label>Discount</Form.Label>
                    <Form.Control type="text" onChange={(e) => this.setState({ discount: e.target.value })} value={this.state.discount} placeholder="Discount" />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="shipping">
                    <Form.Label>Shipping</Form.Label>
                    <Form.Control type="text" onChange={(e) => this.setState({ shipping: e.target.value })} value={this.state.shipping} placeholder="Shipping" />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="quantity">
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control type="text" onChange={(e) => this.setState({ quantity: e.target.value })} value={this.state.quantity} placeholder="Quantity" />
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Form.Group controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control type="text" onChange={(e) => this.setState({ description: e.target.value })} value={this.state.description} placeholder="Description" />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="close">
                    <Form.Label>Close</Form.Label>
                    <Button variant="danger" type="button" size="lg" block onClick={this.handleClose}>
                      Close
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
