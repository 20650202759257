import React, { Component } from "react";
import Axios from "axios";

import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

import { Button, Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      searchInput: ""
    };
  }

  componentDidMount() {
    this.getReviewData();
  }

  //DELETE FUNCTION
  delete = id => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL + "/review/" + id
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getReviewData();
    });
  };

  //STATUS FUNCTION
  status = (id, status) => {
    Axios({
      method: "put",
      url: process.env.REACT_APP_API_URL + "/review/status/" + id,
      data: { status }
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getReviewData();
    });
  };

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  // Display Trigger
  async getReviewData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/review");
    this.setState({ data: result.data });
  }

  // Filter Table
  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return (
        value.country.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.city.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.area.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.status.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  render() {
    const columns = [
      { Header: "Title", accessor: "title" },
      { Header: "Description", accessor: "description" },
      {
        Header: "Vehicle",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <span className="mr-2 pr-2 border-right">
              ST: {row?._original?.style}
            </span>
            <span className="mr-2 pr-2 border-right">
              C: {row?._original?.comfort}
            </span>
            <span className="mr-2 pr-2 border-right">
              P: {row?._original?.performance}
            </span>
            <span className="mr-2 pr-2 border-right">
              V: {row?._original?.value}
            </span>
            <span className="mr-2 pr-2 border-right">
              F: {row?._original?.fuel}
            </span>
          </div>
        )
      },
      {
        Header: "Dealer",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <span className="mr-2 pr-2 border-right">
              B: {row?._original?.buying}
            </span>
            <span className="mr-2 pr-2 border-right">
              S: {row?._original?.service}
            </span>
            <span className="mr-2 pr-2 border-right">
              SL: {row?._original?.selection}
            </span>
          </div>
        )
      },
      { Header: "Status", accessor: "status" },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <FontAwesomeIcon
              className="text-danger mr-3"
              icon={faTrash}
              onClick={() => this.delete(row.id)}
            />
            <FontAwesomeIcon
              className="text-warning ml-3"
              onClick={() => this.status(row.id, 3)}
              icon={faTimes}
            />
            <FontAwesomeIcon
              className="text-success ml-3"
              onClick={() => this.status(row.id, 2)}
              icon={faCheck}
            />
          </div>
        )
      }
    ];

    return (
      <>
        <div className="card mt-2">
          <div className="card-body">
            <div className="">
              <span className="fw-bold ml-3 pr-3 border-dark border-right">
                ST: <span className="text-muted">Style</span>
              </span>
              <span className="fw-bold ml-3 pr-3 border-dark border-right">
                C: <span className="text-muted">Comfort</span>
              </span>
              <span className="fw-bold ml-3 pr-3 border-dark border-right">
                P: <span className="text-muted">Performance</span>
              </span>
              <span className="fw-bold ml-3 pr-3 border-dark border-right">
                V: <span className="text-muted">Cash Value</span>
              </span>
              <span className="fw-bold ml-3 pr-3 border-dark border-right">
                F: <span className="text-muted">Fuel Economy</span>
              </span>
              <span className="fw-bold ml-3 pr-3 border-dark border-right">
                B: <span className="text-muted">Buying Process</span>
              </span>
              <span className="fw-bold ml-3 pr-3 border-dark border-right">
                S: <span className="text-muted">Vehicle Selection</span>
              </span>
              <span className="fw-bold ml-3 pr-3 border-dark border-right">
                SL: <span className="text-muted">Level of Service</span>
              </span>
            </div>

            <div className="my-3 ">
              <label>Search: </label>
              <input
                name="searchInput"
                onChange={this.handleChange}
                label="Search"
                className="ml-3"
              />
            </div>
            <ReactTable
              data={
                this.state.filteredData && this.state.filteredData.length
                  ? this.state.filteredData
                  : this.state.data
              }
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </div>

        {/*here its edited modal */}
        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
