import React, { Component } from "react";
import Axios from "axios";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
// import moment from "moment";
import moment from "moment-timezone";

export class Inventory extends Component {
  delete = (id) => {
    if (window.confirm("Are you sure, you want to delete?") === true) {
      Axios({
        method: "delete",
        url: process.env.REACT_APP_API_URL + "/inventory/" + id,
      }).then((res) => {
        if (res.data.error === false) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        this.getInventoryData();
      });
    } else {
      alert("Delete Cancelled");
    }
  };

  async getInventoryData(status = "") {
    const result = await Axios.get(process.env.REACT_APP_API_URL + `/inventory${status ? `?status=${status}` : ""}`);
    this.setState({ data: result.data });
  }

  componentDidMount() {
    this.getInventoryData();
  }

  // Filter Table
  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter((value) => {
      return (
        value.category?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.maker?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.model?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.generation?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.variation?.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      country: [],
      color: [],
      searchInput: "",
    };
  }

  render() {
    const columns = [
      { Header: "Category", accessor: "category" },
      { Header: "Maker", accessor: "maker" },
      { Header: "Model", accessor: "model" },
      { Header: "Generation", accessor: "generation" },
      { Header: "Variation", accessor: "variation" },
      { Header: "Price", accessor: "price" },
      {
        Header: "Created",
        accessor: "created_at",
        Cell: ({ row }) =>
          moment(row.created_at, "YYYY-MM-DD HH:mm:ss")
            .local()
            .format("Do MMM, YYYY"),
      },
      {
        Header: "Updated",
        accessor: "updated_at",
        Cell: ({ row }) =>
          row.updated_at
            ? moment(row.updated_at, "YYYY-MM-DD HH:mm:ss")
                .local()
                .format("Do MMM, YYYY")
            : "",
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <FontAwesomeIcon className="text-danger mr-3" icon={faTrash} onClick={() => this.delete(row.id)} />
            <Link to={`/inventory/edit/${row.id}`}>
              <FontAwesomeIcon className="text-info ml-3" icon={faPencilAlt} />
            </Link>
          </div>
        ),
      },
    ];

    return (
      <>
        <div className="card mt-2">
          <div className="card-body">
            <Link className="btn btn-primary" to="/inventory/add">
              Add Inventory
            </Link>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="d-flex flex-wrap">
              <div className="my-3">
                <label>Search: </label>
                <input name="searchInput" onChange={this.handleChange} label="Search" className="form-control" />
              </div>
              <div className="my-3">
                <label>Status: </label>
                <select name="searchInput" onChange={(e) => this.getInventoryData(e.target.value)} label="Search" className="ml-3 form-control" style={{ width: "175px" }}>
                  <option value=""></option>
                  <option>Featured</option>
                  <option>Expired</option>
                  <option>Upcoming</option>
                  <option>Popular</option>
                </select>
              </div>
            </div>
            <ReactTable data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : this.state.data} columns={columns} defaultPageSize={10} />
          </div>
        </div>

        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
