import React, { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";

const PriceUpdater = () => {
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPrices();
  }, []);

  const update = () => {
    setLoading("Updating Prices");

    Axios.post(`${process.env.REACT_APP_API_URL}/inventory/updater`)
      .then(() => {
        fetchPrices();
        setLoading("Successfully Updated");
      })
      .catch(() => {
        setLoading("Update Failed");
      });
  };

  const fetchPrices = () => {
    Axios.get(`${process.env.REACT_APP_API_URL}/inventory/updater`).then((res) => {
      const { data } = res;
      setPrices(data);
    });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6">
          <h4 className="mt-2">Update Price</h4>
        </div>
        <div className="col-sm-6 mb-3">
          <div className="d-flex justify-content-end">
            <button onClick={update} disabled={loading} className="btn btn-primary btn-sm">
              {loading || "Update"}
            </button>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body bg-white">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Vehicle</th>
                    <th>Price</th>
                    <th>Last Updated</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {prices &&
                    prices.map((price) => {
                      const color = price.status == "Not Found / Error" ? "text-danger" : "text-success";
                      return (
                        <tr>
                          <td>{price.vehicle}</td>
                          <td>{price.price}</td>
                          <td>{moment(price.updated).format("Do MMM Y")}</td>
                          <td className={color}>{price.status}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceUpdater;
