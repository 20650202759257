import React, { Component } from 'react'
import Axios from 'axios'

import ReactTable from "react-table-v6"
import 'react-table-v6/react-table.css'

import {Button, Form, Modal, Image, Col} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Blog extends Component {

  //DELETE FUNCTION
  delete = (id) => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL+'/blog/'+id,
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getBlogData()
    })
  }

  //Edit FUNCTION
  edit = (id) => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL+'/blog/'+id,
    }).then(res => {
      this.setState({
        id: id,
        cto_id: res.data[0].cto_id,
        ecategory: res.data[0].category,

        categorylink: res.data[0].categorylink,
        title: res.data[0].title,
        bloglink: res.data[0].bloglink,
        date: res.data[0].date,
        description: res.data[0].description,
      })
    })
    this.handleShow()
  }

  eSubmit = (event, id) => {
    event.preventDefault()
    Axios({
      method: 'put',
      url: process.env.REACT_APP_API_URL+'/blog/'+this.state.id,
      data: {
        category: this.state.cto_id,
        categorylink: this.state.categorylink,
        title: this.state.title,
        bloglink: this.state.bloglink,
        date: this.state.date,
        description: this.state.description
      }
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getBlogData()
    })
    this.handleClose()
  }
  handleClose = () => this.setState({show: false});
  handleShow = () => this.setState({show: true});

  // Submit Trigger
  submit = (event) => {
    event.preventDefault()
    var formData = new FormData()
    formData.append('category', this.categoryRef.current.value)
    formData.append('categorylink', this.categorylinkRef.current.value)
    formData.append('title', this.titleRef.current.value)
    formData.append('bloglink', this.bloglinkRef.current.value)
    formData.append('date', this.dateRef.current.value)
    formData.append('description', this.descriptionRef.current.value)
    if (this.state.imageData.length !== 0)
      formData.append('image', this.state.imageData, this.state.imageData.name)
    Axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL+'/blog',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getBlogData()
    })
  }

  // Display Trigger
  async getBlogData(){
    const result = await Axios.get(process.env.REACT_APP_API_URL+'/blog');
    this.setState({data: result.data});
  }

  componentDidMount(){
    this.getBlogData()
  }

  // Filter Table
  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch()
    });
  };
  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return (
          value.category.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    this.setState({ filteredData });
  };

  constructor(props){
    super(props)
    this.state = {
      imageData : [],
      data: [],
      category: [],
      filteredData: [],
      searchInput: "",
      fileURL: '',

      // Edit
      show: false,
      id: '',
      cto_id:'',
      ecategory: [],
      categorylink:[],
      title:[],
      bloglink:[],
      date:[],
      description:[]
    }

    this.categoryRef = React.createRef(null)
    this.categorylinkRef = React.createRef(null)
    this.titleRef = React.createRef(null)
    this.bloglinkRef = React.createRef(null)
    this.dateRef = React.createRef(null)
    this.descriptionRef = React.createRef(null)
  }

  render() {

    const columns = [
      { Header: 'Logo', accessor: 'image',
        Cell: ({row}) => (
          <img src={`${process.env.REACT_APP_AWS_URL}${row.image}`} height="30px" width="30px" />
        )
      },
      { Header: 'Category', accessor: 'category'},
      { Header: 'Category Link', accessor: 'categorylink',
        Cell: ({row}) => (
          <a href={row.categorylink} target="_blank">Link</a>
        )
      },
      { Header: 'Title', accessor: 'title' },
      { Header: 'Blog Link', accessor: 'bloglink',
        Cell: ({row}) => (
          <a href={row.bloglink} target="_blank">Link</a>
        )
      },
      { Header: 'Description', accessor: 'description' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Actions' ,  accessor: 'id',
        Cell: ({row}) => (
          <div>
             <FontAwesomeIcon className="text-danger mr-3" icon={faTrash} onClick={() => this.delete(row.id)} />
             <FontAwesomeIcon className="text-info ml-3" onClick={() => this.edit(row.id)} icon={faPencilAlt} />
          </div>
        )
      }
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">

            <form onSubmit={this.submit} ref={this.form}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="category">
                    <Form.Label>Category</Form.Label>
                    <Form.Control type="text" placeholder="Enter Category" ref={this.categoryRef} >
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="link">
                    <Form.Label>Category Link</Form.Label>
                    <Form.Control type="text" ref={this.categorylinkRef} placeholder="Enter Category Link"/>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="image">
                    <Form.Label>Image</Form.Label>
                     <Form.File label="Blog Logo" custom type="file" accept="image/*"
                       onChange={(e) => this.setState({imageData: e.target.files[0], fileURL: URL.createObjectURL(e.target.files[0])})}
                     />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="title">
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" ref={this.titleRef} placeholder="Enter Title"/>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="bloglink">
                    <Form.Label>Blog Link</Form.Label>
                    <Form.Control type="text" ref={this.bloglinkRef} placeholder="Enter Blog Link"/>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="date">
                    <Form.Label>Date</Form.Label>
                    <Form.Control type="date" ref={this.dateRef}/>
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Form.Group controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows={3} ref={this.descriptionRef} />
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </form>

            <div>
              <Image src={this.state.fileURL} height="100px" width="auto"/>
            </div>

          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <label>Search: </label>
              <input
                name="searchInput"
                onChange={this.handleChange}
                label="Search"
                className="ml-3"
              />
            </div>
            <ReactTable
               data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : this.state.data}
               columns={columns}
               defaultPageSize={10}
            />
          </div>
        </div>

        {/*here its edited modal */}
        <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Body>
              <form onSubmit={this.eSubmit}>
                <input type="hidden" name="image_name" value={this.state.logo} />
                <div className="row">
                  <div className="col-sm-6">
                  <Form.Group controlId="Category">
                    <Form.Label>Category</Form.Label>
                    <Form.Control as="select" onChange={(e) => this.setState({cto_id: e.target.value})}>
                      <optgroup label="Selected">
                        <option value={this.state.cto_id}>{this.state.ecategory}</option>
                      </optgroup>
                      <optgroup label="Options">
                        {this.state.category.map((ecategory) => (
                          <option value={ecategory.id}>{ecategory.category}</option>
                        ))}
                      </optgroup>
                    </Form.Control>
                  </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="link">
                      <Form.Label>Category Link</Form.Label>
                      <Form.Control type="text" onChange={(e) => this.setState({categorylink: e.target.value})} value={this.state.categorylink}  placeholder="Category Link"/>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="title">
                      <Form.Label>Title</Form.Label>
                      <Form.Control type="text" onChange={(e) => this.setState({title: e.target.value})} value={this.state.title}  placeholder="Title"/>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="bloglink">
                      <Form.Label>Blog Link</Form.Label>
                      <Form.Control type="text" onChange={(e) => this.setState({bloglink: e.target.value})} value={this.state.bloglink}  placeholder="Blog Link"/>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="date">
                      <Form.Label>Date</Form.Label>
                      <Form.Control type="date" onChange={(e) => this.setState({date: e.target.value})} value={this.state.date}/>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="description">
                      <Form.Label>Description</Form.Label>
                      <Form.Control as="textarea" rows={3} onChange={(e) => this.setState({description: e.target.value})} value={this.state.description}  placeholder="Description"/>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="city">
                      <Form.Label>Logo</Form.Label>
                       <Form.File label="Maker Logo" custom type="file" accept="image/*"
                          onChange={(e) => this.setState({imageData: e.target.files[0]})}
                       />
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="close">
                      <Form.Label>Close</Form.Label>
                      <Button variant="danger" type="button" size="lg" block onClick={this.handleClose}>
                        Close
                      </Button>
                    </Form.Group>
                  </div>
                </div>
              </form>
            </Modal.Body>
        </Modal>
        <ToastContainer autoClose={3000} />
      </>
    );

  }

}
