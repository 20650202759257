import React, { Component } from "react";
import Axios from "axios";
import moment from "moment";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      searchInput: "",
    };
  }

  componentDidMount() {
    this.getPaymentData();
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  // Display Trigger
  async getPaymentData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/as");
    this.setState({ data: result.data });
  }

  // Filter Table
  handleChange = (event, status) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch(status);
    });
  };

  globalSearch = (status) => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter((value) => {
      return status
        ? value.status.toLowerCase().includes(searchInput.toLowerCase())
        : value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.chassis?.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.contact?.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.email?.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.country?.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.status.toLowerCase().includes(searchInput.toLowerCase());
    });
    this.setState({ filteredData });
  };

  openLink = (row) => {
    if (window.confirm("Are you sure?")) {
      window.location.href = `https://autoones.com/auction-sheet/success/${((row._original.id * 394672 + 7638) * 2).toString(16)}`;
    }
  };

  render() {
    const columns = [
      {
        Header: "Id",
        accessor: "id",
        Cell: ({ row }) => (
          <a onClick={() => this.openLink(row)} target="_blank" className={`text-center font-weight-bold ${row._original.status == "Successful" ? "bg-success text-white" : "bg-danger text-white"}`}>
            {row._original.id}
          </a>
        ),
      },
      { Header: "Name", accessor: "name" },
      { Header: "Phone", accessor: "contact" },
      { Header: "Email", accessor: "email" },
      { Header: "CC", accessor: "country" },
      { Header: "Chassis", accessor: "chassis" },
      {
        Header: "Package",
        accessor: "packages",
        Cell: ({ row }) => (row._original.packages == 1 ? "Auction Sheet" : row._original.packages == 2 ? "AS + Translate" : row._original.packages == 3 ? "Sheet Plus" : "Translation"),
      },
      {
        Header: "Created",
        accessor: "created_at",
        Cell: ({ row }) => moment(row._original.createdAt).format("MMMM Do, YYYY / h:m a"),
      },
      { Header: "Price", accessor: "price" },
      {
        Header: "Method",
        accessor: "paymentMethod",
        Cell: ({ row }) => (row._original.paymentMethod == 1 ? "Stripe" : "Paypal"),
      },
    ];

    return (
      <>
        <div className="card mt-2">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-3 form-group my-3 ml-3">
                <label>Search: </label>
                <input name="searchInput" className="form-control" onChange={(e) => this.handleChange(e, null)} label="Search" />
              </div>
              <div className="col-sm-3 form-group my-3 ml-3">
                <label>Search: </label>
                <select name="searchInput" className="form-control" onChange={(e) => this.handleChange(e, e.target.value)} label="Search">
                  <option value="">--- Select ---</option>
                  <option value="Initiated">Inititated</option>
                  <option value="Successful">Successful</option>
                  <option value="Failed">Failed</option>
                </select>
              </div>
            </div>
            <ReactTable data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : this.state.data} columns={columns} defaultPageSize={10} />
          </div>
        </div>

        {/*here its edited modal */}
        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
