import React, { Component } from 'react'
import Axios from 'axios'
import ReactTable from "react-table-v6"
import 'react-table-v6/react-table.css'

import {Button, Form, Modal} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Subcategory extends Component {

  //DELETE FUNCTION
  delete = (id) => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL+'/subcategory/'+id,
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getSubcategoryData()
    })
  }

  //Edit FUNCTION
  edit = (id) => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL+'/subcategory/'+id,
    }).then(res => {
      this.setState({
        id: id,
        scto_id: res.data[0].scto_id,
        eshopcategory: res.data[0].shopcategory,
        subcategory: res.data[0].subcategory
      })
    })
    this.handleShow()
  }

  eSubmit = (event, id) => {
    event.preventDefault()
    Axios({
      method: 'put',
      url: process.env.REACT_APP_API_URL+'/subcategory/'+this.state.id,
      data: {
        shopcategory: this.state.scto_id,
        subcategory: this.state.subcategory
      }
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getSubcategoryData()
    })
    this.handleClose()
  }
  handleClose = () => this.setState({show: false});
  handleShow = () => this.setState({show: true});

  // Submit Trigger
  submit = (event) => {
    event.preventDefault()
    Axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL+'/subcategory',
      data: {
        shopcategory: this.shopcategoryRef.current.value,
        subcategory: this.subcategoryRef.current.value
      }
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getSubcategoryData()
    })
  }

  // Display Trigger
  async getShopcategoryData(){
    const result = await Axios.get(process.env.REACT_APP_API_URL+'/shopcategory');
    this.setState({shopcategory: result.data});
  }

  async getSubcategoryData(){
      const result = await Axios.get(process.env.REACT_APP_API_URL+'/subcategory');
      this.setState({data: result.data});
  }

  componentDidMount(){
    this.getShopcategoryData()
    this.getSubcategoryData()
  }

  // Filter Table
  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch()
    });
  };

  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return (
          value.category.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    this.setState({ filteredData });
  };

  constructor(props){
    super(props)
    this.state = {
      data: [],
      filteredData: [],
      shopcategory: [],
      searchInput: "",

      // Edit
      show: false,
      id: '',
      scto_id:'',
      eshopcategory: [],
      subcategory:[]
    }
    this.shopcategoryRef = React.createRef(null)
    this.subcategoryRef = React.createRef(null)
  }

  render() {

    const columns = [
      { Header: 'Shop Category', accessor: 'shopcategory' },
      { Header: 'Subcategory', accessor: 'subcategory' },
      { Header: 'Actions', accessor:'id',
        Cell: ({row}) => (
          <div>
             <FontAwesomeIcon className="text-danger mr-3" icon={faTrash} onClick={() => this.delete(row.id)} />
             <FontAwesomeIcon className="text-info ml-3" onClick={() => this.edit(row.id)} icon={faPencilAlt} />
          </div>
        )
      }
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
          <Form onSubmit={this.submit}>
            <div className="row">

              <div className="col-sm-6">
              <Form.Group controlId="shopcategory">
                <Form.Label>Shop Category</Form.Label>
                <Form.Control as="select" ref={this.shopcategoryRef} >
                  <option value="">-- Select --</option>
                  {this.state.shopcategory.map((place) => (
                    <option value={place.id}>{place.shopcategory}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group controlId="subcategory">
                  <Form.Label>Subcategory</Form.Label>
                  <Form.Control type="text" ref={this.subcategoryRef} required={true} />
                </Form.Group>
              </div>

              <div className="col-sm-12">
                <Form.Group controlId="submit">
                <Form.Label>Submit</Form.Label>
                <Button variant="primary" type="submit" size="lg" block>
                  Submit
                </Button>
                </Form.Group>
              </div>
            </div>
          </Form>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <label>Search: </label>
              <input
                name="searchInput"
                onChange={this.handleChange}
                label="Search"
                className="ml-3"
              />
            </div>
            <ReactTable
               data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : this.state.data}
               columns={columns}
               defaultPageSize={10}
            />
          </div>
        </div>

        {/*here its edited modal */}

        <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Body>
              <Form onSubmit={this.eSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                  <Form.Group controlId="Shop Category">
                    <Form.Label>Shop Category</Form.Label>
                    <Form.Control as="select" onChange={(e) => this.setState({scto_id: e.target.value})} value={this.state.scto_id}>
                      <optgroup label="Selected">
                        <option value={this.state.scto_id}>{this.state.eshopcategory}</option>
                      </optgroup>
                      <optgroup label="Options">
                        {this.state.shopcategory.map((eshopcategory) => (
                          <option value={eshopcategory.id}>{eshopcategory.shopcategory}</option>
                        ))}
                      </optgroup>
                    </Form.Control>
                  </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="Sub Category">
                      <Form.Label>Sub Category</Form.Label>
                      <Form.Control type="text" onChange={(e) => this.setState({subcategory: e.target.value})} value={this.state.subcategory}  placeholder="Sub Category"/>
                    </Form.Group>
                  </div>
                  <div className="col-sm-6">
                    <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="close">
                      <Form.Label>Close</Form.Label>
                      <Button variant="danger" type="button" size="lg" block onClick={this.handleClose}>
                        Close
                      </Button>
                    </Form.Group>
                  </div>
                </div>
              </Form>
            </Modal.Body>
        </Modal>
        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
