/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function DashboardAsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open ` : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className={`menu-item ${getMenuItemActive("/dashboard", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        {/* Location */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Location</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* Countries */}
        <li className={`menu-item ${getMenuItemActive("/country", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/country">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Compass.svg")} />
            </span>
            <span className="menu-text">Countries</span>
          </NavLink>
        </li>
        {/*  end::1 Countries*/}

        {/* State */}
        <li className={`menu-item ${getMenuItemActive("/state", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/state">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Location-arrow.svg")} />
            </span>
            <span className="menu-text">State</span>
          </NavLink>
        </li>
        {/*  end::1 Countries*/}

        {/* Cities*/}
        <li className={`menu-item ${getMenuItemActive("/city", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/city">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Direction1.svg")} />
            </span>
            <span className="menu-text">Cities</span>
          </NavLink>
        </li>
        {/*  end::1 Cities*/}

        {/* Area */}
        <li className={`menu-item ${getMenuItemActive("/area", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/area">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")} />
            </span>
            <span className="menu-text">Areas</span>
          </NavLink>
        </li>
        {/*  end::1 AREA*/}
        {/* Location */}

        {/* Categories */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Categories Data & Info</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*Start*/}
        <li className={`menu-item ${getMenuItemActive("/category", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/category">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Midi.svg")} />
            </span>
            <span className="menu-text">Categories</span>
          </NavLink>
        </li>
        {/*end::1 Maker*/}

        {/*Start*/}
        <li className={`menu-item ${getMenuItemActive("/maker", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/maker">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Cassete.svg")} />
            </span>
            <span className="menu-text">Maker</span>
          </NavLink>
        </li>
        {/*end::1 Maker*/}

        {/*Start:1 Model*/}
        <li className={`menu-item ${getMenuItemActive("/model", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/model">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Interselect.svg")} />
            </span>
            <span className="menu-text">Model</span>
          </NavLink>
        </li>
        {/*end::1 Model*/}

        {/*Start:1 Generation*/}
        <li className={`menu-item ${getMenuItemActive("/generation", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/generation">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Vertical.svg")} />
            </span>
            <span className="menu-text">Generation</span>
          </NavLink>
        </li>
        {/*end::1 Generation*/}

        {/*Start:1 Variation*/}
        <li className={`menu-item ${getMenuItemActive("/variation", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/variation">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Patch.svg")} />
            </span>
            <span className="menu-text">Variation</span>
          </NavLink>
        </li>
        {/*end::1 Variation*/}

        {/*Start:1 Body Type*/}
        <li className={`menu-item ${getMenuItemActive("/bodytype", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/bodytype">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Airplay.svg")} />
            </span>
            <span className="menu-text">Body Type</span>
          </NavLink>
        </li>
        {/*end::1 Body Type*/}

        {/* New */}
        {/* begin::section */}
        <li className="menu-section">
          <h4 className="menu-text">Advance & Other's</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*Start:1 Gropus*/}
        <li className={`menu-item ${getMenuItemActive("/groups", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/groups">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Airplay.svg")} />
            </span>
            <span className="menu-text">Gropus</span>
          </NavLink>
        </li>
        {/*end::1 Gropus*/}
        {/*Start*/}
        <li className={`menu-item ${getMenuItemActive("/features", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/features">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Midi.svg")} />
            </span>
            <span className="menu-text">Features</span>
          </NavLink>
        </li>
        {/*end::1 Features*/}

        {/*Start*/}
        <li className={`menu-item ${getMenuItemActive("/specifications", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/specifications">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Clip.svg")} />
            </span>
            <span className="menu-text">Specifications</span>
          </NavLink>
        </li>
        {/*end::1 Specifications*/}

        {/*Start:1 Advance*/}
        <li className={`menu-item ${getMenuItemActive("/advance", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/advance">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Swiss-knife.svg")} />
            </span>
            <span className="menu-text">Advance</span>
          </NavLink>
        </li>
        {/*end::1 Advance*/}

        {/*Start:1 Color*/}
        <li className={`menu-item ${getMenuItemActive("/color", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/color">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Roller.svg")} />
            </span>
            <span className="menu-text">Color</span>
          </NavLink>
        </li>
        {/*end::1 Color*/}

        {/*Start:1 Suggestions*/}
        <li className={`menu-item ${getMenuItemActive("/suggestions", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/suggestions">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Text/H1.svg")} />
            </span>
            <span className="menu-text">Suggestions</span>
          </NavLink>
        </li>
        {/*end::1 Suggestions*/}

        <li className="menu-section">
          <h4 className="menu-text">Listing & Inventory</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/*begin::1 Advertisment*/}
        <li className={`menu-item ${getMenuItemActive("/user", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/user">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">User Ads</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/product", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/product">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")} />
            </span>
            <span className="menu-text">Parts Ads</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/listing", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/listing">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-grid.svg")} />
            </span>
            <span className="menu-text">Listing Stat</span>
          </NavLink>
        </li>
        {/*end::1 Advertisment*/}

        {/*Start:1 Inventroy*/}
        <li className={`menu-item ${getMenuItemActive("/inventory", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/inventory">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")} />
            </span>
            <span className="menu-text">New Vehicles</span>
          </NavLink>
        </li>
        {/*end::1 Inventroy*/}
        {/*Start:1 Comparison*/}
        <li className={`menu-item ${getMenuItemActive("/comparison", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/comparison">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Roulette.svg")} />
            </span>
            <span className="menu-text">Comparison</span>
          </NavLink>
        </li>
        {/*end::1 Comparison*/}
        <li className={`menu-item ${getMenuItemActive("/reviews", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/reviews">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/key.svg")} />
            </span>
            <span className="menu-text">Reviews</span>
          </NavLink>
        </li>
        {/*end::1 Reviews*/}
        <li className={`menu-item ${getMenuItemActive("/price-updater", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/price-updater">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Price1.svg")} />
            </span>
            <span className="menu-text">Price Updater</span>
          </NavLink>
        </li>
        {/*end::1 Reviews*/}

        <li className="menu-section">
          <h4 className="menu-text">Auction Sheet's</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        {/*begin::1 Custom Page*/}
        <li className={`menu-item ${getMenuItemActive("/custom", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/custom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Compiled-file.svg")} />
            </span>
            <span className="menu-text">Custom Page</span>
          </NavLink>
        </li>
        {/*end::1 Saved Reports*/}

        {/*begin::1 Custom Page*/}
        <li className={`menu-item ${getMenuItemActive("/report", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/report">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File-plus.svg")} />
            </span>
            <span className="menu-text">Saved Reports</span>
          </NavLink>
        </li>
        {/*end::1 Saved Reports*/}

        {/*begin::1 Custom Page*/}
        <li className={`menu-item ${getMenuItemActive("/payment", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/payment">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")} />
            </span>
            <span className="menu-text">Payment Details</span>
          </NavLink>
        </li>
        {/*end::1 Saved Reports*/}

        {/*begin::1 User*/}
        <li className="menu-section">
          <h4 className="menu-text">Websites</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/*end::1 User*/}

        {/*begin::1 Advertisment*/}
        <li className={`menu-item ${getMenuItemActive("/advertisment", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/advertisment">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Advertisment</span>
          </NavLink>
        </li>
        {/*end::1 Advertisment*/}

        {/*begin::1 Error*/}
        <li className={`menu-item ${getMenuItemActive("/error-log", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/error-log">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Error</span>
          </NavLink>
        </li>
        {/*end::1 Error*/}

        {/*begin::1 SEO*/}
        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/seo", false)}`} aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/seo">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
            </span>
            <span className="menu-text">SEO</span>
          </NavLink>
        </li>
        {/*end::1 SEO*/}

        {/*begin::Queries*/}
        <li className={`menu-item ${getMenuItemActive("/queries", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/queries">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")} />
            </span>
            <span className="menu-text">Queries</span>
          </NavLink>
        </li>
        {/*end::1 Queries*/}

        {/*begin::Reviews*/}

        {/*Start:1 Bike*/}
        <li className={`menu-item ${getMenuItemActive("/package", true)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/package">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/ATM.svg")} />
            </span>
            <span className="menu-text">Ad Packages</span>
          </NavLink>
        </li>
        {/*end::1 Bike*/}

        {/*Start:1 Bike*/}
        <li className={`menu-item ${getMenuItemActive("/package/auctionsheet", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/package/auctionsheet">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/ATM.svg")} />
            </span>
            <span className="menu-text">AuctionSheet Packages</span>
          </NavLink>
        </li>
        {/*end::1 Bike*/}

        {/* New */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Users</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*Start:1 Bike*/}
        <li className={`menu-item ${getMenuItemActive("/individual", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/individual">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sort1.svg")} />
            </span>
            <span className="menu-text">Individual</span>
          </NavLink>
        </li>
        {/*end::1 Bike*/}

        {/*Start:1 Rikshaw*/}
        <li className={`menu-item ${getMenuItemActive("/dealer", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/dealer">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-1.svg")} />
            </span>
            <span className="menu-text">Dealer</span>
          </NavLink>
        </li>
        {/*end::1 Rikshaw*/}

        {/* New */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Videos</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*Start:1 Videos*/}
        <li className={`menu-item ${getMenuItemActive("/video", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/video">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Airplay-video.svg")} />
            </span>
            <span className="menu-text">Videos</span>
          </NavLink>
        </li>
        {/*end::1 Videos*/}
        {/*Start:1 Category*/}
        <li className={`menu-item ${getMenuItemActive("/video/category", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/video/category">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Airplay.svg")} />
            </span>
            <span className="menu-text">Category</span>
          </NavLink>
        </li>
        {/*end::1 Category*/}

        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Prices</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        <li className={`menu-item ${getMenuItemActive("/fuel-prices", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/fuel-prices">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")} />
            </span>
            <span className="menu-text">Fuel Prices</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/mileage-calculator", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/mileage-calculator">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")} />
            </span>
            <span className="menu-text">Mileage Calculator</span>
          </NavLink>
        </li>

        {/* New */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Faq</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*Start:1 Faq*/}
        <li className={`menu-item ${getMenuItemActive("/faq", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/faq">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Airplay-video.svg")} />
            </span>
            <span className="menu-text">Faq</span>
          </NavLink>
        </li>
        {/*end::1 Faq*/}
        {/*Start:1 Category*/}
        <li className={`menu-item ${getMenuItemActive("/faq/category", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/faq/category">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Airplay.svg")} />
            </span>
            <span className="menu-text">Category</span>
          </NavLink>
        </li>
        {/*end::1 Category*/}

        {/*Start:1 Blog*/}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Blog</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        <li className={`menu-item ${getMenuItemActive("/blog", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/blog">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Airplay-video.svg")} />
            </span>
            <span className="menu-text">Blog</span>
          </NavLink>
        </li>
        {/*end::1 Blog*/}

        {/* New */}

        {/* New */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Shop</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Category*/}
        <li className={`menu-item ${getMenuItemActive("/shopcategory", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/shopcategory">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Folder.svg")} />
            </span>
            <span className="menu-text">Shop Category</span>
          </NavLink>
        </li>
        {/*end::1 Category*/}

        {/*begin::1 Sub Category*/}
        <li className={`menu-item ${getMenuItemActive("/subcategory", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/subcategory">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Other1.svg")} />
            </span>
            <span className="menu-text">Sub Category</span>
          </NavLink>
        </li>
        {/*end::1 Sub Category*/}
      </ul>
    </>
  );
}
