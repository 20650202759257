import React, { Component } from 'react'
import Axios from 'axios'

import ReactTable from "react-table-v6"
import 'react-table-v6/react-table.css'

import {Button, Form, Col, Modal} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Video extends Component {

  //DELETE FUNCTION
  delete = (id) => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL+'/video/'+id,
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getVideoData()
    })
  }

  //Edit FUNCTION
  edit = (id) => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL+'/video/'+id,
    }).then(res => {
      this.setState({
        id: id,
        vc_id: res.data[0].vc_id,
        evideocategory: res.data[0].videocategory,
        link: res.data[0].link,
        title: res.data[0].title,
        sourcetype: res.data[0].sourcetype

      })
    })
    this.handleShow()
  }

  eSubmit = (event, id) => {
    event.preventDefault()
    Axios({
      method: 'put',
      url: process.env.REACT_APP_API_URL+'/video/'+this.state.id,
      data: {
        videocategory: this.state.vc_id,
        link: this.state.link,
        title: this.state.title,
        sourcetype: this.state.sourcetype
      }
    })
    .then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getVideoData()
    })
  }
  handleClose = () => this.setState({show: false});
  handleShow = () => this.setState({show: true});

  // Submit Trigger
  submit = (event) => {
    event.preventDefault()
    Axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL+'/video',
      data: {
        videocategory: this.videocategoryRef.current.value,
        link: this.linkRef.current.value,
        title: this.titleRef.current.value,
        sourcetype: this.sourcetypeRef.current.value

      }
    })
    .then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getVideoData()
    })
  }

  async getVideoData(){
      const result = await Axios.get(process.env.REACT_APP_API_URL+'/video');
      this.setState({data: result.data});
  }

  async getVideocategoryData(){
      const result = await Axios.get(process.env.REACT_APP_API_URL+'/videocategory');
      this.setState({videocategory: result.data});
  }

  componentDidMount(){
    this.getVideocategoryData()
    this.getVideoData()
  }

  // Filter Table
  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch()
    });
  };
  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return (
          value.videocategory.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    this.setState({ filteredData });
  };

  constructor(props){
    super(props)
    this.state = {
      data: [],
      filteredData: [],
      videocategory: [],
       Data: [],
      searchInput: "",

      // Edit
      show: false,
      id: '',
      vc_id: '',
      evideocategory: [''],
      title:[''],
      link:[''],
      sourcetype:['']
    }
    this.videocategoryRef = React.createRef(null)
    this.linkRef = React.createRef(null)
    this.titleRef = React.createRef(null)
    this.sourcetypeRef = React.createRef(null)
  }

  render() {

    const columns = [
      { Header: 'Video Category', accessor: 'videocategory' },
      { Header: 'Title', accessor: 'title' },
      { Header: 'SourceId', accessor: 'link' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Actions' , accessor:'id',
        Cell: ({row}) => (
          <div>
             <FontAwesomeIcon className="text-danger mr-3" icon={faTrash} onClick={() => this.delete(row.id)} />
             <FontAwesomeIcon className="text-info ml-3" onClick={() => this.edit(row.id)} icon={faPencilAlt} />
          </div>
        )
      }
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
          <Form onSubmit={this.submit}>
            <div className="row">
            <div className="col-sm-6">
            <Form.Group controlId="Video category">
              <Form.Label> Video Category</Form.Label>
              <Form.Control as="select" ref={this.videocategoryRef} >
                <option value="">-- Select --</option>
                {this.state.videocategory.map((place) => (
                  <option value={place.id}>{place.videocategory}</option>
                ))}
              </Form.Control>
            </Form.Group>
            </div>
            <div className="col-sm-6">
              <Form.Group controlId="city">
                <Form.Label>Source Id</Form.Label>
                <Form.Control type="text" ref={this.linkRef} placeholder="Source Id" />
              </Form.Group>
            </div>

            <div className="col-sm-6">
              <Form.Group controlId="city">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" ref={this.titleRef} placeholder="Title" />
              </Form.Group>
            </div>

            <div className="col-sm-6 d-none">
              <Form.Group controlId="city">
                <Form.Label>Source Type</Form.Label>
                <Form.Control type="text" ref={this.sourcetypeRef} placeholder="Title" />
              </Form.Group>
            </div>

              <div className="col-sm-6">
                <Form.Group controlId="submit">
                <Form.Label>Submit</Form.Label>
                <Button variant="primary" type="submit" size="lg" block>
                  Submit
                </Button>
                </Form.Group>
              </div>
            </div>
          </Form>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <label>Search: </label>
              <input
                name="searchInput"
                onChange={this.handleChange}
                label="Search"
                className="ml-3"
              />
            </div>
            <ReactTable
               data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : this.state.data}
               columns={columns}
               defaultPageSize={10}
            />
          </div>
        </div>

        {/*here its edited modal*/}
        <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Body>
              <Form onSubmit={this.eSubmit}>
                <div className="row">

                <div className="col-sm-6">
                <Form.Group controlId="Video category">
                  <Form.Label> Video Category</Form.Label>
                  <Form.Control as="select" onChange={(e) => this.setState({vc_id: e.target.value})}  value={this.state.vc_id} >
                    <optgroup label="Selected">
                      <option value={this.state.vc_id}>{this.state.evideocategory}</option>
                    </optgroup>
                    <optgroup label="Options">
                      {this.state.videocategory.map((evideocategory) => (
                        <option value={evideocategory.id}>{evideocategory.videocategory}</option>
                      ))}
                    </optgroup>
                  </Form.Control>
                </Form.Group>
                </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="country">
                      <Form.Label>Link</Form.Label>
                      <Form.Control type="text" onChange={(e) => this.setState({link: e.target.value})}  value={this.state.link} placeholder="Video Link" />
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="country">
                      <Form.Label>Title</Form.Label>
                      <Form.Control type="text" onChange={(e) => this.setState({title: e.target.value})}  value={this.state.title} placeholder="Video Title" />
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="country">
                      <Form.Label>Source Type</Form.Label>
                      <Form.Control type="text" onChange={(e) => this.setState({sourcetype: e.target.value})}  value={this.state.sourcetype} placeholder="Video Source Type" />
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="close">
                      <Form.Label>Close</Form.Label>
                      <Button variant="danger" type="button" size="lg" block onClick={this.handleClose}>
                        Close
                      </Button>
                    </Form.Group>
                  </div>
                </div>
              </Form>
            </Modal.Body>
        </Modal>
        <ToastContainer autoClose={3000} />
      </>
    );

  }

}
