import React, { Component } from 'react'
import Axios from 'axios'

import ReactTable from "react-table-v6"
import 'react-table-v6/react-table.css'

import {Button, Form, Modal} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Faq extends Component {

  //DELETE FUNCTION
  delete = (id) => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL+'/faq/'+id,
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getFAQData()
    })
  }

  //Edit FUNCTION
  edit = (id) => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL+'/faq/'+id,
    }).then(res => {
      this.setState({
        id: id,
        fc_id: res.data[0].fc_id,
        question: res.data[0].question,
        answers: res.data[0].answers
      })
    })
    this.handleShow()
  }
  eSubmit = (event, id) => {
    event.preventDefault()
    Axios({
      method: 'put',
      url: process.env.REACT_APP_API_URL+'/faq/'+this.state.id,
      data: {
        faqcategory: this.state.fc_id,
        question: this.state.question,
        answers: this.state.answers,
      }
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getFAQData()
    })
    this.handleClose()
  }
  handleClose = () => this.setState({show: false});
  handleShow = () => this.setState({show: true});


  // Submit Trigger
  submit = (event) => {
    event.preventDefault()
    Axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL+'/faq',
      data: {
        faqcategory: this.faqcategoryRef.current.value,
        question: this.questionRef.current.value,
        answers: this.answersRef.current.value

      }
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getFAQData()
    })
  }

  async getFaqcategoryData(){
      const result = await Axios.get(process.env.REACT_APP_API_URL+'/faqcategory');
      this.setState({faqcategory: result.data});
    }

  async getFAQData(){
      const result = await Axios.get(process.env.REACT_APP_API_URL+'/faq');
      this.setState({data: result.data});
  }

  componentDidMount(){
    this.getFaqcategoryData()
    this.getFAQData()
  }

  // Filter Table
  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch()
    });
  };
  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return (
          value.faqcategory.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    this.setState({ filteredData });
  };

  constructor(props){
    super(props)
    this.state = {
      data: [],
      filteredData: [],
      faqcategory: [],
       Data: [],
      searchInput: "",

      // Edit
      show: false,
      id: '',
      fc_id:'',
      question: [],
      answers:[],
      efaqcategory:[]
    }
    this.faqcategoryRef = React.createRef(null)
    this.questionRef = React.createRef(null)
    this.answersRef = React.createRef(null)
  }

  render() {

    const columns = [
      { Header: 'Faq Category', accessor: 'faqcategory' },
      { Header: 'Question', accessor: 'question' },
      { Header: 'Answers', accessor: 'answers' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Actions', accessor:'id',
        Cell: ({row}) => (
          <div>
             <FontAwesomeIcon className="text-danger mr-3" icon={faTrash} onClick={() => this.delete(row.id)} />
             <FontAwesomeIcon className="text-info ml-3" onClick={() => this.edit(row.id)} icon={faPencilAlt} />
          </div>
        )
      }
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
          <Form onSubmit={this.submit}>
            <div className="row">

              <div className="col-sm-6">
              <Form.Group controlId="Video category">
                <Form.Label> Faq Category</Form.Label>
                <Form.Control as="select" ref={this.faqcategoryRef} required={true} >
                  <option value="">-- Select --</option>
                  {this.state.faqcategory.map((faqcategory) => (
                    <option value={faqcategory.id}>{faqcategory.faqcategory}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group controlId="city">
                  <Form.Label>Questions</Form.Label>
                  <Form.Control type="text" ref={this.questionRef} placeholder="Questions" />
                </Form.Group>
              </div>

            <div className="col-sm-6">
              <Form.Group controlId="city">
                <Form.Label>Answers</Form.Label>
                <Form.Control type="text" ref={this.answersRef} placeholder="Answers" />
              </Form.Group>
            </div>

              <div className="col-sm-6">
                <Form.Group controlId="submit">
                <Form.Label>Submit</Form.Label>
                <Button variant="primary" type="submit" size="lg" block>
                  Submit
                </Button>
                </Form.Group>
              </div>
            </div>
          </Form>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <label>Search: </label>
              <input
                name="searchInput"
                onChange={this.handleChange}
                label="Search"
                className="ml-3"
              />
            </div>
            <ReactTable
               data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : this.state.data}
               columns={columns}
               defaultPageSize={10}
            />
          </div>
        </div>

        {/*here its edited modal */}
        <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Body>
              <Form onSubmit={this.eSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                  <Form.Group controlId="Faq Category">
                    <Form.Label>Faq Category</Form.Label>
                    <Form.Control as="select" onChange={(e) => this.setState({fc_id: e.target.value})} value={this.state.fc_id}>
                      <optgroup label="Selected">
                        <option value={this.state.fc_id}>{this.state.efaqcategory}</option>
                      </optgroup>
                      <optgroup label="Options">
                        {this.state.faqcategory.map((efaqcategory) => (
                          <option value={efaqcategory.id}>{efaqcategory.faqcategory}</option>
                        ))}
                      </optgroup>
                    </Form.Control>
                  </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="Question">
                      <Form.Label>Question</Form.Label>
                      <Form.Control type="text" onChange={(e) => this.setState({question: e.target.value})} value={this.state.question}  placeholder="Questions"/>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="Answers">
                      <Form.Label>Answers</Form.Label>
                      <Form.Control type="text" onChange={(e) => this.setState({answers: e.target.value})} value={this.state.answers}  placeholder="Questions"/>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="close">
                      <Form.Label>Close</Form.Label>
                      <Button variant="danger" type="button" size="lg" block onClick={this.handleClose}>
                        Close
                      </Button>
                    </Form.Group>
                  </div>
                </div>
              </Form>
            </Modal.Body>
        </Modal>
      <ToastContainer autoClose={3000} />
      </>
    );

  }

}
