import React, { Component } from "react";
import Axios from "axios";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

import { Button, Form, Modal, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class Category extends Component {
  //DELETE FUNCTION
  delete = id => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL + "/category/" + id
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getCategoryData();
    });
  };

  //Edit FUNCTION
  edit = id => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/category/" + id
    }).then(res => {
      this.setState({
        id: res.data[0].id,
        category: res.data[0].category,
        logo: res.data[0].logo
      });
    });
    this.handleShow();
  };

  eSubmit = (event, id) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append("category", this.state.category);
    formData.append("image_name", this.state.logo);
    if (this.state.imageData.length !== 0)
      formData.append("logo", this.state.imageData, this.state.imageData.name);
    Axios({
      method: "put",
      url: process.env.REACT_APP_API_URL + "/category/" + this.state.id,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getCategoryData();
    });
    this.handleClose();
  };

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  // Submit Trigger
  submit = event => {
    event.preventDefault();
    var formData = new FormData();
    formData.append("category", this.categoryRef.current.value);
    if (this.state.imageData.length !== 0)
      formData.append("logo", this.state.imageData, this.state.imageData.name);
    Axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/category",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getCategoryData();
    });
  };

  // Display Trigger
  async getCategoryData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/category");
    this.setState({ data: result.data });
  }

  componentDidMount() {
    this.getCategoryData();
  }

  // Filter Table
  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };
  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return (
        value.category.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.status.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      searchInput: "",
      imageData: [],
      fileURL: "",

      // Edit
      show: false,
      id: "",
      category: [""],
      logo: ""
    };
    this.categoryRef = React.createRef(null);
  }

  render() {
    const columns = [
      {
        Header: "logo",
        accessor: "logo",
        Cell: ({ row }) => (
          <img
            src={`${process.env.REACT_APP_AWS_URL}${row.logo}`}
            alt="Logo"
            height="30px"
            width="30px"
          />
        )
      },
      { Header: "Category", accessor: "category" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <FontAwesomeIcon
              className="text-danger mr-3"
              icon={faTrash}
              onClick={() => this.delete(row.id)}
            />
            <FontAwesomeIcon
              className="text-info ml-3"
              onClick={() => this.edit(row._original.slug, row.id)}
              icon={faPencilAlt}
            />
          </div>
        )
      }
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
            <Form onSubmit={this.submit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="city">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      type="text"
                      ref={this.categoryRef}
                      placeholder="Category"
                      required={true}
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="image">
                    <Form.Label>Image</Form.Label>
                    <Form.File
                      label="Category Logo"
                      custom
                      type="file"
                      accept="image/*"
                      onChange={e =>
                        this.setState({
                          imageData: e.target.files[0],
                          fileURL: URL.createObjectURL(e.target.files[0])
                        })
                      }
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>

            <Image src={this.state.fileURL} height="100px" width="auto" />
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <label>Search: </label>
              <input
                name="searchInput"
                onChange={this.handleChange}
                label="Search"
                className="ml-3"
              />
            </div>
            <ReactTable
              data={
                this.state.filteredData && this.state.filteredData.length
                  ? this.state.filteredData
                  : this.state.data
              }
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </div>

        {/*here its edited modal*/}
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Body>
            <Form onSubmit={this.eSubmit}>
              <input type="hidden" name="image_name" value={this.state.logo} />
              <div className="row">
                <div className="col-sm-12">
                  <Form.Group controlId="Category">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e =>
                        this.setState({ category: e.target.value })
                      }
                      value={this.state.category}
                      placeholder="Category"
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="image">
                    <Form.Label>Image</Form.Label>
                    <Form.File
                      label="Category Logo"
                      custom
                      type="file"
                      accept="image/*"
                      onChange={e =>
                        this.setState({
                          imageData: e.target.files[0],
                          fileURL: URL.createObjectURL(e.target.files[0])
                        })
                      }
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="close">
                    <Form.Label>Close</Form.Label>
                    <Button
                      variant="danger"
                      type="button"
                      size="lg"
                      block
                      onClick={this.handleClose}
                    >
                      Close
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
