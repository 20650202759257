import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { CountryPage } from "./pages/CountryPage";
import { MakerPage } from "./pages/MakerPage";
import { ModelPage } from "./pages/ModelPage";
import { SEOPage } from "./pages/SEOPage";
import { QueriesPage } from "./pages/QueriesPage";
import { AdvertismentPage } from "./pages/AdvertismentPage";
import { PaymentPage } from "./pages/PaymentPage";
import { ReportPage } from "./pages/ReportPage";
import { CustomPage } from "./pages/CustomPage";
import { CategoryPage } from "./pages/CategoryPage";
import { SubcategoryPage } from "./pages/SubcategoryPage";
import { ProductPage } from "./pages/ProductPage";
import { AddProductPage } from "./pages/AddproductPage";
import { ProductreviewPage } from "./pages/ProductreviewPage";
import { CityPage } from "./pages/CityPage";
import { StatePage } from "./pages/StatePage";
import { AreaPage } from "./pages/AreaPage";
import { GenerationPage } from "./pages/GenerationPage";
import { VariationPage } from "./pages/VariationPage";
import { FeaturedPage } from "./pages/FeaturedPage";
import { IndividualPage } from "./pages/IndividualPage";
import { DealerPage } from "./pages/DealerPage";
import { PackagePage } from "./pages/PackagePage";
import { ASPackagePage } from "./pages/ASPackagePage";
import { VideocategoryPage } from "./pages/VideocategoryPage";
import { VideoPage } from "./pages/VideoPage";
import { BodytypePage } from "./pages/BodytypePage";
import { FaqcategoryPage } from "./pages/FaqcategoryPage";
import { FaqPage } from "./pages/FaqPage";
import { NewpaymentPage } from "./pages/NewpaymentPage";
import { GroupsPage } from "./pages/GroupsPage";
import { SpecificationsPage } from "./pages/SpecificationsPage";
import { FeaturesPage } from "./pages/FeaturesPage";
import { InventoryPage } from "./pages/InventoryPage";
import { AdvancePage } from "./pages/AdvancePage";
import { AddinventoryPage } from "./pages/AddinventoryPage";
import { ShopcategoryPage } from "./pages/ShopcategoryPage";
import { EditinventoryPage } from "./pages/EditinventoryPage";
import { ListingPage } from "./pages/ListingPage";
import { AuctionsheetPage } from "./pages/AuctionsheetPage";
import { UserPage } from "./pages/UserPage";
import { ColorPage } from "./pages/ColorPage";
import { ComparisonPage } from "./pages/ComparisonPage";
import { BlogPage } from "./pages/BlogPage";
import { SuggestionsPage } from "./pages/SuggestionsPage";
import { ReviewPage } from "./pages/ReviewPage";
import { Error } from "./pages/Error";
import FuelPrices from "./pages/Prices/fuelPrices";
import MileageCalculator from "./pages/Prices/mileageCalculator";
import PriceUpdater from "./pages/PriceUpdater";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute exact path="/error-log" component={Error} />
        <ContentRoute exact path="/dashboard" component={DashboardPage} />
        <ContentRoute exact path="/country" render={(props) => <CountryPage />} />
        <ContentRoute exact path="/state" render={(props) => <StatePage />} />
        <ContentRoute exact path="/maker" component={MakerPage} />
        <ContentRoute exact path="/model" component={ModelPage} />
        <ContentRoute exact path="/seo" component={SEOPage} />
        <ContentRoute exact path="/queries" component={QueriesPage} />
        <ContentRoute exact path="/advertisment" component={AdvertismentPage} />
        <ContentRoute exact path="/payment" component={PaymentPage} />
        <ContentRoute exact path="/report" component={ReportPage} />
        <ContentRoute exact path="/custom" component={CustomPage} />
        <ContentRoute exact path="/category" component={CategoryPage} />
        <ContentRoute exact path="/subcategory" component={SubcategoryPage} />
        <ContentRoute exact path="/product" component={ProductPage} />
        <ContentRoute exact path="/addproduct" component={AddProductPage} />
        <ContentRoute exact path="/productreview" component={ProductreviewPage} />
        <ContentRoute exact path="/builder" component={BuilderPage} />
        <ContentRoute exact path="/city" component={CityPage} />
        <ContentRoute exact path="/area" component={AreaPage} />
        <ContentRoute exact path="/generation" component={GenerationPage} />
        <ContentRoute exact path="/variation" component={VariationPage} />
        <ContentRoute exact path="/newpayment" component={NewpaymentPage} />
        <ContentRoute exact path="/featured" component={FeaturedPage} />
        <ContentRoute exact path="/individual" component={IndividualPage} />
        <ContentRoute exact path="/dealer" component={DealerPage} />
        <ContentRoute exact path="/package/auctionsheet" component={ASPackagePage} />
        <ContentRoute exact path="/package" component={PackagePage} />
        <ContentRoute exact path="/reviews" component={ReviewPage} />
        <ContentRoute exact path="/video/category" component={VideocategoryPage} />
        <ContentRoute exact path="/video" component={VideoPage} />
        <ContentRoute exact path="/bodytype" component={BodytypePage} />
        <ContentRoute exact path="/faq/category" component={FaqcategoryPage} />
        <ContentRoute exact path="/faq" component={FaqPage} />
        <ContentRoute exact path="/groups" component={GroupsPage} />
        <ContentRoute exact path="/specifications" component={SpecificationsPage} />
        <ContentRoute exact path="/features" component={FeaturesPage} />
        <ContentRoute exact path="/inventory" component={InventoryPage} />
        <ContentRoute exact path="/inventory/add/" component={AddinventoryPage} />
        <ContentRoute exact path="/advance" component={AdvancePage} />
        <ContentRoute exact path="/shopcategory" component={ShopcategoryPage} />
        <ContentRoute exact path="/inventory/edit/:id" component={EditinventoryPage} />
        <ContentRoute exact path="/listing" component={ListingPage} />
        <ContentRoute exact path="/auctionsheet" component={AuctionsheetPage} />
        <ContentRoute exact path="/user" component={UserPage} />
        <ContentRoute exact path="/color" component={ColorPage} />
        <ContentRoute exact path="/comparison" component={ComparisonPage} />
        <ContentRoute exact path="/blog" component={BlogPage} />
        <ContentRoute exact path="/suggestions" component={SuggestionsPage} />
        <ContentRoute exact path="/my-page" component={MyPage} />
        <ContentRoute exact path="/fuel-prices" component={FuelPrices} />
        <ContentRoute exact path="/mileage-calculator" component={MileageCalculator} />
        <ContentRoute exact path="/price-updater" component={PriceUpdater} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
