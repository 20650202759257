import React, { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const FuelPrices = () => {
  const [id, setId] = useState();
  const [prices, setPrices] = useState([]);
  const [price, setPrice] = useState();
  const [date, setDate] = useState();
  const [type, setType] = useState();

  useEffect(() => {
    getPrices();
  }, []);

  const getPrices = () => {
    Axios.get(`${process.env.REACT_APP_API_URL}/fuel-prices`).then((res) => {
      setPrices(res.data);
    });
  };

  const submit = (event) => {
    event.preventDefault();

    if (id) {
      const data = { price, date, type };
      Axios.put(`${process.env.REACT_APP_API_URL}/fuel-prices/${id}`, data).then((res) => {
        getPrices();
        setId();
      });
    } else {
      const data = { price, date, type };
      Axios.post(`${process.env.REACT_APP_API_URL}/fuel-prices`, data).then((res) => {
        getPrices();
      });
    }
  };

  const deletePrice = (id) => {
    Axios.delete(`${process.env.REACT_APP_API_URL}/fuel-prices/${id}`).then((res) => {
      getPrices();
    });
  };

  const editPrice = (price) => {
    setId(price.id);
    setPrice(price.price);
    setDate(moment(price.date).format("YYYY-MM-DD"));
    setType(price.type);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body bg-white">
              <form onSubmit={submit}>
                <div className="form-group">
                  <label>Price</label>
                  <input required value={price} onChange={(e) => setPrice(e.target.value)} name="price" type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label>Date</label>
                  <input required value={date} onChange={(e) => setDate(e.target.value)} name="date" type="date" className="form-control" />
                </div>
                <div className="form-group">
                  <label>Type</label>
                  <select required onChange={(e) => setType(e.target.value)} name="type" className="form-control">
                    <option></option>
                    <option selected={type === "Petrol"}>Petrol</option>
                    <option selected={type === "Diesel"}>Diesel</option>
                    <option selected={type === "Kerosene"}>Kerosene</option>
                  </select>
                </div>
                <button className="btn btn-light" type="submit">
                  {id ? "Update" : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="col-sm-8">
          <div className="card">
            <div className="card-body bg-white">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Price</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {prices.map((price, i) => (
                    <tr>
                      <td>{price.price}</td>
                      <td>{price.type}</td>
                      <td>{moment(price.date).format("Do, MMM YYYY")}</td>
                      <td>
                        <i className="mx-3 text-danger" onClick={() => deletePrice(price.id)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </i>
                        <i className="mx-3 text-info" onClick={() => editPrice(price)}>
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FuelPrices;
