import React, { Component } from "react";
import Axios from "axios";
import { Modal, Button } from "react-bootstrap";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { CSVLink } from "react-csv";
import Form from "./Form";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faBan, faLightbulb, faUser, faUserCircle, faDotCircle, faAddressBook, faUnlink } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReactTooltip from "react-tooltip";

export class Dealer extends Component {
  constructor(props) {
    super(props);
    this.download = this.download.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      data: [],
      filteredData: [],
      dataToDownload: [],
      show: false,
      sheets: false,
    };
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  componentDidMount() {
    this.getUserData();
  }

  actions = (action, id) => {
    Axios({
      method: "put",
      url: process.env.REACT_APP_API_URL + "/users/" + action + "/" + id,
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getUserData();
    });
  };

  updatesheets = (sheets, id) => {
    var updatedSheets = 0;
    if (this.state.sheets) updatedSheets = this.state.sheets;
    else updatedSheets = sheets;

    if (window.confirm("are you sure, you want to update?")) {
      Axios.put(process.env.REACT_APP_API_URL + "/users/sheets", { sheets: updatedSheets, id }).then((res) => {
        toast.success("updated sheets");
      });
    }
  };

  download(event) {
    const currentRecords = this.reactTable.getResolvedState().sortedData;
    var data_to_download = [];
    for (var index = 0; index < currentRecords.length; index++) {
      let record_to_download = {};
      for (var colIndex = 0; colIndex < this.columns.length; colIndex++) {
        record_to_download[this.columns[colIndex].Header] = currentRecords[index][this.columns[colIndex].accessor];
      }
      data_to_download.push(record_to_download);
    }
    this.setState({ dataToDownload: data_to_download }, () => {
      // click the CSVLink component to trigger the CSV download
      this.csvLink.link.click();
    });
  }

  // Display Trigger
  async getUserData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/users/Dealer");
    this.setState({ user: result.data });
  }

  // Filter Table
  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };
  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter((value) => {
      return (
        value.category.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.maker.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.status.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Email", accessor: "email" },
    { Header: "Phone", accessor: "phone" },
    { Header: "Role", accessor: "role" },
    {
      Header: "Sheets",
      accessor: "sheets",
      Cell: ({ row }) => (
        <div>
          <input style={{ width: "30px" }} onChange={(e) => this.setState({ sheets: e.target.value })} defaultValue={row._original.sheets} name="sheets" />
          <input onClick={() => this.updatesheets(row._original.sheets, row._original.id)} type="submit" value="ok" className="border-0 p-1 bg-info" />
        </div>
      ),
    },
    { Header: "Joined", accessor: "created_at", Cell: ({ row }) => moment(row._original.created_at).format("Do, MMM YYYY") },
    { Header: "Certified", accessor: "isCertified" },
    { Header: "Dealer Type", accessor: "dealertype" },
    { Header: "Staus", accessor: "status" },
    {
      Header: "Actions",
      accessor: "id",
      maxWidth: 1000,
      Cell: ({ row }) => (
        <div style={{ width: "50px" }}>
          <FontAwesomeIcon className="text-info" icon={faCheck} onClick={() => this.actions("Active", row.id)} title="Active" />
          <FontAwesomeIcon className="text-danger ml-3" icon={faTimes} onClick={() => this.actions("Inactive", row.id)} title="Inactive" />
          <FontAwesomeIcon className="ml-3" icon={faUser} onClick={() => this.actions("Admin", row.id)} title="Admin" />
          <FontAwesomeIcon className="ml-3" style={{ color: "DarkViolet" }} onClick={() => this.actions("User", row.id)} icon={faUserCircle} title="User" />
          <FontAwesomeIcon className="ml-3" style={{ color: "DarkRed" }} onClick={() => this.actions("Certified", row.id)} icon={faDotCircle} title="Certified" />
          <FontAwesomeIcon className="ml-3" style={{ color: "Gold" }} onClick={() => this.actions("New", row.id)} icon={faAddressBook} title="Dealer New" />
          <FontAwesomeIcon className="ml-3" style={{ color: "Indigo" }} onClick={() => this.actions("Use", row.id)} icon={faUnlink} title="Dealer Old" />
        </div>
      ),
    },
  ];

  render() {
    return (
      <>
        <div className="card mb-3">
          <div className="card-body">
            <h5>Create Showrooms</h5>
            <Form />
          </div>
        </div>
        <div className="card mt-2">
          <div className="card-body">
            <div className="row my-3">
              <div className="col-sm-6">
                <label>Search: </label>
                <input name="searchInput" onChange={this.handleChange} label="Search" className="ml-3" />
              </div>
              <div className="col-sm-6">
                <div className="d-flex flex-row justify-content-end">
                  <button onClick={this.download} className="btn btn-danger">
                    Download
                  </button>
                </div>
              </div>
            </div>

            <CSVLink data={this.state.dataToDownload} filename="data.csv" className="hidden" ref={(r) => (this.csvLink = r)} target="_blank" />
            <ReactTable
              ref={(r) => (this.reactTable = r)}
              data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : this.state.user}
              columns={this.columns}
              defaultPageSize={10}
            />
          </div>
        </div>

        <ReactTooltip place="right" type="success" effect="solid" />
        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
