import React, { Component } from "react";
import Axios from "axios";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

import { Button, Form, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import ChipInput from "material-ui-chip-input";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class Advance extends Component {
  //DELETE FUNCTION
  delete = id => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL + "/advance/" + id
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getAdvanceData();
    });
  };

  edit = id => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/advance/" + id
    }).then(res => {
      this.setState({
        id: id,
        cto_id: res.data[0].cto_id,
        ecategory: res.data[0].category,
        advance: res.data[0].advance,
        image: res.data[0].image
      });
    });
    this.handleShow();
  };

  eSubmit = (event, id) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append("category", this.state.cto_id);
    formData.append("advance", this.state.advance);
    formData.append("image_name", this.state.image);
    if (this.state.imageData.length !== 0)
      formData.append("image", this.state.imageData, this.state.imageData.name);
    Axios({
      method: "put",
      url: process.env.REACT_APP_API_URL + "/advance/" + this.state.id,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getAdvanceData();
    });
    this.handleClose();
  };

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  // Submit Trigger
  submit = event => {
    event.preventDefault();
    var formData = new FormData();
    formData.append("advance", this.advanceRef.current.value);
    formData.append(
      "options",
      this.optionsRef.current ? this.optionsRef.current.state.chips : ""
    );
    formData.append("category", this.categoryRef.current.value);

    if (this.state.imageData.length !== 0)
      formData.append("image", this.state.imageData, this.state.imageData.name);
    Axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/advance",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getAdvanceData();
    });
  };

  /* Select Check */
  selectCheck = event => {
    if (event.target.checked) {
      this.setState({ selectCheck: true });
    } else {
      this.setState({ selectCheck: false });
    }
  };

  // Display Trigger
  async getCategoryData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/category");
    this.setState({ category: result.data });
  }
  async getAdvanceData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/advance");
    this.setState({ data: result.data });
  }

  componentDidMount() {
    this.getAdvanceData();
    this.getCategoryData();
  }

  // Filter Table
  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return (
        value.category.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.advance.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.status.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  handleChangeAdvance = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearchAdvance();
    });
  };

  globalSearchAdvance = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return value.category.toLowerCase().includes(searchInput.toLowerCase());
    });
    const nullData = [
      {
        id: "null",
        category: "null",
        advance: "null",
        option: "null",
        status: "null"
      }
    ];
    const searchData = filteredData.length ? filteredData : nullData;
    this.setState({ filteredData: searchData });
  };

  /* Tags */

  /* Tags */

  constructor(props) {
    super(props);
    this.state = {
      imageData: [],
      data: [],
      category: [],
      filteredData: [],
      advance: [],
      searchInput: "",
      selectCheck: false,
      tags: "",

      // Edit
      show: false,
      id: "",
      cto_id: "",
      ecategory: [],
      advance: []
    };
    this.categoryRef = React.createRef(null);
    this.advanceRef = React.createRef(null);
    this.optionsRef = React.createRef(null);
  }

  render() {
    const columns = [
      {
        Header: "Image",
        accessor: "image",
        Cell: ({ row }) => (
          <img
            src={`${process.env.REACT_APP_AWS_URL}${row.image}`}
            alt="Image"
            height="30px"
            width="30px"
          />
        )
      },
      { Header: "Advance", accessor: "advance" },
      { Header: "Category", accessor: "category" },
      {
        Header: "Options",
        accessor: "options",
        Cell: ({ row }) => `${JSON.parse(row.options)}`
      },
      { Header: "Status", accessor: "status" },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <FontAwesomeIcon
              className="text-danger mr-3"
              icon={faTrash}
              onClick={() => this.delete(row.id)}
            />
            <FontAwesomeIcon
              className="text-info ml-3"
              onClick={() => this.edit(row.id)}
              icon={faPencilAlt}
            />
          </div>
        )
      }
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
            <Form onSubmit={this.submit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="category">
                    <Form.Label>Category</Form.Label>
                    <Form.Control as="select" ref={this.categoryRef}>
                      <option value="">-- Select --</option>
                      {this.state.category.map(place => (
                        <option value={place.id}>{place.category}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="advance">
                    <Form.Label>Advance</Form.Label>
                    <Form.Control
                      type="text"
                      ref={this.advanceRef}
                      placeholder="Advance"
                    />
                    <Form.Check
                      type="checkbox"
                      onChange={event => this.selectCheck(event)}
                      label="Add Select Option"
                    />
                  </Form.Group>
                </div>

                {this.state.selectCheck && (
                  <div className="col-sm-6">
                    <Form.Group controlId="advance">
                      <Form.Label>Select Options</Form.Label>
                      <ChipInput
                        className="form-control"
                        ref={this.optionsRef}
                      />
                    </Form.Group>
                  </div>
                )}

                <div className="col-sm-6">
                  <Form.Group controlId="image">
                    <Form.Label>Image</Form.Label>
                    <Form.File
                      label="Advance Image"
                      custom
                      type="file"
                      accept="image/*"
                      onChange={e =>
                        this.setState({
                          imageData: e.target.files[0],
                          fileURL: URL.createObjectURL(e.target.files[0])
                        })
                      }
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <div className="col-sm-6" onChange={this.handleChangeAdvance}>
                <Form.Group controlId="Body category">
                  <Form.Label>Category</Form.Label>
                  <Form.Control as="select">
                    <option value="">-- Select --</option>
                    {this.state.category.map(place => (
                      <option value={place.category}>{place.category}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <label>Search: </label>
              <input
                name="searchInput"
                onChange={this.handleChange}
                label="Search"
                className="ml-3"
              />
            </div>
            <ReactTable
              data={
                this.state.filteredData && this.state.filteredData.length
                  ? this.state.filteredData
                  : this.state.data
              }
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </div>

        {/*here its edited modal */}
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Body>
            <Form onSubmit={this.eSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="Category">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => this.setState({ cto_id: e.target.value })}
                      value={this.state.cto_id}
                    >
                      <optgroup label="Selected">
                        <option value={this.state.cto_id}>
                          {this.state.ecategory}
                        </option>
                      </optgroup>
                      <optgroup label="Options">
                        {this.state.category.map(ecategory => (
                          <option value={ecategory.id}>
                            {ecategory.category}
                          </option>
                        ))}
                      </optgroup>
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="Advance">
                    <Form.Label>Advance</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => this.setState({ advance: e.target.value })}
                      value={this.state.advance}
                      placeholder="Advance"
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="image">
                    <Form.Label>Image</Form.Label>
                    <Form.File
                      label="Advance Logo"
                      custom
                      type="file"
                      accept="image/*"
                      onChange={e =>
                        this.setState({
                          imageData: e.target.files[0],
                          fileURL: URL.createObjectURL(e.target.files[0])
                        })
                      }
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="close">
                    <Form.Label>Close</Form.Label>
                    <Button
                      variant="danger"
                      type="button"
                      size="lg"
                      block
                      onClick={this.handleClose}
                    >
                      Close
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
