import React, { Component } from "react";
import Axios from "axios";

import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

import { Button, Form, Modal, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class Maker extends Component {
  //DELETE FUNCTION
  delete = id => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL + "/maker/" + id
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getMakerData();
    });
  };

  //Edit FUNCTION
  edit = id => {
    this.setState({ imageData: "" });
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/maker/" + id
    }).then(res => {
      this.setState({
        id: id,
        logo: res.data[0].logo,
        cto_id: res.data[0].cto_id,
        ecategory: res.data[0].category,
        maker: res.data[0].maker,
        description: res.data[0].description
      });
    });
    this.handleShow();
  };

  eSubmit = (event, id) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append("category", this.state.cto_id);
    formData.append("maker", this.state.maker);
    formData.append("description", this.state.description);
    formData.append("image_name", this.state.logo);
    if (this.state.imageData.length !== 0)
      formData.append("logo", this.state.imageData, this.state.imageData.name);
    Axios({
      method: "put",
      url: process.env.REACT_APP_API_URL + "/maker/" + this.state.id,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getMakerData();
    });
    this.handleClose();
  };

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  // Submit Trigger
  submit = event => {
    event.preventDefault();
    var formData = new FormData();
    formData.append("category", this.categoryRef.current.value);
    formData.append("maker", this.makerRef.current.value);
    formData.append("description", this.descriptionRef.current.value);
    if (this.state.imageData.length !== 0)
      formData.append("logo", this.state.imageData, this.state.imageData.name);
    Axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/maker",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData
    }).then(res => {
      if (res.data.errno) {
        toast.error("Not Submitted");
      } else {
        toast.success(res.data.message);
      }
      this.getMakerData();
    });
  };

  // Display Trigger
  async getCategoryData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/category");
    this.setState({ category: result.data });
  }
  async getMakerData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/maker");
    this.setState({ data: result.data });
  }

  componentDidMount() {
    this.getCategoryData();
    this.getMakerData();
  }

  // Filter Table
  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  handleChange2 = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearchMaker();
    });
  };

  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return (
        value.category.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.maker.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.status.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  globalSearchMaker = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return value.category.toLowerCase().includes(searchInput.toLowerCase());
    });
    const nullData = [
      {
        id: "null",
        logo: "null",
        category: "null",
        maker: "null",
        status: "null"
      }
    ];
    const searchData = filteredData.length ? filteredData : nullData;
    this.setState({ filteredData: searchData });
  };

  constructor(props) {
    super(props);
    this.state = {
      imageData: [],
      data: [],
      category: [],
      filteredData: [],
      maker: [],
      searchInput: "",
      fileURL: "",
      logo: "",
      description: "",
      // Edit
      show: false,
      id: "",
      cto_id: "",
      ecategory: [],
      maker: []
    };
    this.form = React.createRef(null);
    this.categoryRef = React.createRef(null);
    this.logoRef = React.createRef(null);
    this.makerRef = React.createRef(null);
    this.descriptionRef = React.createRef(null);
  }

  render() {
    const columns = [
      {
        Header: "logo",
        accessor: "logo",
        Cell: ({ row }) => (
          <img
            src={`${process.env.REACT_APP_AWS_URL}${row.logo}`}
            height="30px"
            width="30px"
          />
        )
      },
      { Header: "Category", accessor: "category" },
      { Header: "Maker", accessor: "maker" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <FontAwesomeIcon
              className="text-danger mr-3"
              icon={faTrash}
              onClick={() => this.delete(row.id)}
            />
            <FontAwesomeIcon
              className="text-info ml-3"
              onClick={() => this.edit(row.id)}
              icon={faPencilAlt}
            />
          </div>
        )
      }
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
            <form onSubmit={this.submit} ref={this.form}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="category">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as="select"
                      ref={this.categoryRef}
                      required={true}
                    >
                      <option value="">-- Select --</option>
                      {this.state.category.map(place => (
                        <option value={place.id}>{place.category}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="city">
                    <Form.Label>Logo</Form.Label>
                    <Form.File
                      label="Maker Logo"
                      custom
                      type="file"
                      accept="image/*"
                      onChange={e =>
                        this.setState({
                          imageData: e.target.files[0],
                          fileURL: URL.createObjectURL(e.target.files[0])
                        })
                      }
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="city">
                    <Form.Label>Maker</Form.Label>
                    <Form.Control
                      type="text"
                      ref={this.makerRef}
                      placeholder="Maker"
                      required={true}
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Form.Group controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      ref={this.descriptionRef}
                      placeholder="Description"
                      required={true}
                    />
                  </Form.Group>
                </div>
              </div>
            </form>

            <div>
              <Image src={this.state.fileURL} height="100px" width="auto" />
            </div>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <div className="col-sm-6" onChange={this.handleChange2}>
                <Form.Group controlId="category">
                  <Form.Label>Category</Form.Label>
                  <Form.Control as="select">
                    <option value="">-- Select --</option>
                    {this.state.category.map(place => (
                      <option value={place.category}>{place.category}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <hr />
              <label>Search: </label>
              <input
                name="searchInput"
                onChange={this.handleChange}
                label="Search"
                className="ml-3"
              />
            </div>
            <ReactTable
              data={
                this.state.filteredData && this.state.filteredData.length
                  ? this.state.filteredData
                  : this.state.data
              }
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </div>

        {/*here its edited modal */}
        <Modal show={this.state.show} onHide={this.handleClose} centered>
          <Modal.Body>
            <form onSubmit={this.eSubmit}>
              <input type="hidden" name="image_name" value={this.state.logo} />
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="Category">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => this.setState({ cto_id: e.target.value })}
                    >
                      <optgroup label="Selected">
                        <option value={this.state.cto_id}>
                          {this.state.ecategory}
                        </option>
                      </optgroup>
                      <optgroup label="Options">
                        {this.state.category.map(ecategory => (
                          <option value={ecategory.id}>
                            {ecategory.category}
                          </option>
                        ))}
                      </optgroup>
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="Maker">
                    <Form.Label>Maker</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => this.setState({ maker: e.target.value })}
                      value={this.state.maker}
                      placeholder="Maker"
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Form.Group controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      onChange={e =>
                        this.setState({ description: e.target.value })
                      }
                      value={this.state.description}
                      placeholder="Description"
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Form.Group controlId="city">
                    <Form.Label>Logo</Form.Label>
                    <Form.File
                      label="Maker Logo"
                      custom
                      type="file"
                      accept="image/*"
                      onChange={e =>
                        this.setState({ imageData: e.target.files[0] })
                      }
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="close">
                    <Form.Label>Close</Form.Label>
                    <Button
                      variant="danger"
                      type="button"
                      block
                      onClick={this.handleClose}
                    >
                      Close
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
