import React from "react";
import Axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Tabs, Form, Button, Col, Row } from "react-bootstrap";

import { Editor } from "@tinymce/tinymce-react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import chroma from "chroma-js";
import Select, { StylesConfig } from "react-select";

import RUG from "react-upload-gallery";
import "react-upload-gallery/dist/style.css";
import "./addinventory.scss";

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",
  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

export class Addinventory extends React.Component {
  handleEditorChange = (e) => {
    this.setState({ description: e.target.getContent() });
  };
  handleColorChange = (e) => {
    this.setState({ selectedColor: e });
  };

  colourStyles: StylesConfig<color, true> = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.value);
      return {
        ...styles,
        backgroundColor: isDisabled ? undefined : isSelected ? data.value : isFocused ? color.alpha(0.1).css() : undefined,
        color: isDisabled ? "#ccc" : isSelected ? (chroma.contrast(color, "white") > 2 ? "white" : "black") : data.value,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled ? (isSelected ? data.value : color.alpha(0.3).css()) : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.value);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.value,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.value,
      ":hover": {
        backgroundColor: data.value,
        color: "white",
      },
    }),
  };

  // Submit Trigger
  submit = (event) => {
    event.preventDefault();
    this.setState({ disabled: true });

    var popular = this.popularRef.current.checked ? 1 : 0;
    var featured = this.featuredRef.current.checked ? 1 : 0;
    var upcoming = this.upcomingRef.current.checked ? 1 : 0;
    var expired = this.expiredRef.current.checked ? 1 : 0;

    Axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/inventory/",
      data: {
        category: this.categoryRef.current.value,
        month: this.monthRef.current.value,
        year: this.yearRef.current.value,
        price: this.priceRef.current.value,
        maker: this.makerRef.current.value,
        model: this.modelRef.current.value,
        generation: this.generationRef.current.value,
        variation: this.variationRef.current.value,
        description: this.state.description,
        featured: featured,
        upcoming: upcoming,
        expired: expired,
        popular: popular,
        color: JSON.stringify(this.state.selectedColor),
        launchdate: this.launchdateRef.current.value,
      },
    }).then((res) => {
      toast("Please Wait! Your listing is submiting");

      var formDataTwo = new FormData();
      const id = res.data.data;
      formDataTwo.append("id", res.data.data);

      formDataTwo.append("avgMilleage", this.avgMilRef.current.value);
      formDataTwo.append("assemblyType", this.assTypRef.current.value);
      formDataTwo.append("transmission", this.transRef.current.value);
      formDataTwo.append("engineSize", this.engSizRef.current.value);

      const s_id = document.getElementsByName("s_id[]");
      for (var i = 0; i < s_id.length; i++) {
        formDataTwo.append("s_id[]", s_id[i].value);
      }
      const specifications = document.getElementsByName("specifications[]");
      for (var i = 0; i < specifications.length; i++) {
        formDataTwo.append("specifications[]", specifications[i].value?.replace('"', '\\"'));
      }
      const f_id = document.getElementsByName("f_id[]");
      for (var i = 0; i < f_id.length; i++) {
        formDataTwo.append("f_id[]", f_id[i].value);
      }
      const features = document.getElementsByName("features[]");
      for (var i = 0; i < features.length; i++) {
        var val = features[i].checked ? 1 : 0;
        formDataTwo.append("features[]", val);
      }
      if (this.state.images.length !== 0) {
        for (var i = 0; i < this.state.images.length; i++) {
          formDataTwo.append("images[]", this.state.images[i]["file"], this.state.images[i]["file"]["image"]);
        }
      }

      Axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + "/inventory/advance",
        headers: { "Content-Type": "multipart/form-data" },
        data: formDataTwo,
      }).then(() => {
        if (res.data.error === false) {
          this.props.history.push(`/inventory/edit/${id}`);
          toast.success(res.data.message);
        } else {
          this.setState({ disabled: false });
          toast.error(res.data.message);
        }
        this.getCategoryData();
      });
    });
  };

  async getCategoryData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/category");
    this.setState({ category: result.data });
  }
  async getColorData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/color/option");
    this.setState({ color: result.data });
  }
  async getVariationData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/variation");
    this.setState({ variation: result.data });
  }
  //Get all models
  async getModelData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/model");
    this.setState({ model: result.data });
  }
  //Get all generation
  async getGenerationData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/generation");
    this.setState({ generation: result.data });
  }
  // Category OnChange Function
  async getFormData(id) {
    var result = await Axios.get(process.env.REACT_APP_API_URL + "/specifications/category/" + id);
    this.setState({ specifications: result.data });
    this.setState({ specOptions: JSON.parse(`[${result.data[0].spec_options.replaceAll(']"', "]").replaceAll('"[', "[")}]`) });

    result = await Axios.get(process.env.REACT_APP_API_URL + "/features/category/" + id);
    this.setState({ features: result.data });

    result = await Axios.get(process.env.REACT_APP_API_URL + "/maker/category/" + id);
    this.setState({ maker: result.data });
  }
  // Category OnChange Function
  async getModel(id) {
    var result = await Axios.get(process.env.REACT_APP_API_URL + "/model/maker/" + id);
    this.setState({ model: result.data });
  }
  async getGenAndVar(id) {
    var result = await Axios.post(process.env.REACT_APP_API_URL + "/generation/mm/", {
      maker: this.makerRef.current.value,
      model: this.modelRef.current.value,
    });
    this.setState({ generation: result.data });

    result = await Axios.get(process.env.REACT_APP_API_URL + "/variation/model/" + id);
    this.setState({ variation: result.data });
  }

  componentDidMount() {
    this.getCategoryData();
    this.getColorData();
  }

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      category: [],
      color: [],
      maker: [],
      model: [],
      generation: [],
      variation: [],
      color: [],
      launchdate: [],
      assemble: [],
      searchInput: "",
      description: "",
      featured: "",
      upcoming: "",
      expired: "",
      popular: "",
      selectedColor: [],
      images: [],
      specOptions: [],
      advance: [],
      specifications: [],
      features: [],
      disabled: false,
    };
    this.yearRef = React.createRef(null);
    this.priceRef = React.createRef(null);
    this.monthRef = React.createRef(null);
    this.YearRef = React.createRef(null);
    this.makerRef = React.createRef(null);
    this.modelRef = React.createRef(null);
    this.generationRef = React.createRef(null);
    this.variationRef = React.createRef(null);
    this.categoryRef = React.createRef(null);
    this.featuredRef = React.createRef(null);
    this.upcomingRef = React.createRef(null);
    this.expiredRef = React.createRef(null);
    this.popularRef = React.createRef(null);
    this.colorRef = React.createRef(null);
    this.launchdateRef = React.createRef(null);
    this.avgMilRef = React.createRef(null);
    this.assTypRef = React.createRef(null);
    this.transRef = React.createRef(null);
    this.engSizRef = React.createRef(null);

    this.a_idRef = React.createRef(null);
    this.advanceRef = React.createRef(null);

    this.s_idRef = React.createRef(null);
    this.specificationsRef = React.createRef(null);

    this.f_idRef = React.createRef(null);
    this.featuresRef = React.createRef(null);
  }

  render() {
    return (
      <>
        <Form onSubmit={this.submit}>
          <div className="card mt-2">
            <div className="card-body">
              <Tabs defaultActiveKey="basic" className="mb-3">
                <Tab eventKey="basic" title="Basic">
                  <div className="row pt-5">
                    <div className="col-sm-6">
                      <Form.Group controlId="category">
                        <Form.Label>Category</Form.Label>
                        <Form.Control as="select" ref={this.categoryRef} onChange={(e) => this.getFormData(e.target.value)}>
                          <option value="">-- Select --</option>
                          {this.state.category.map((place) => (
                            <option value={place.id}>{place.category}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </div>

                    <div className="col-sm-6">
                      <Form.Group controlId="color">
                        <Form.Label>Color</Form.Label>
                        <Select isMulti options={this.state.color} onChange={this.handleColorChange} styles={this.colourStyles} />
                      </Form.Group>
                    </div>

                    <div className="col-sm-6">
                      <Form.Group controlId="Year">
                        <Form.Label>Year</Form.Label>
                        <Form.Control type="text" ref={this.yearRef} placeholder="Enter Year" />
                      </Form.Group>
                    </div>

                    <div className="col-sm-6">
                      <Form.Group controlId="month">
                        <Form.Label>Month</Form.Label>
                        <Form.Control as="select" ref={this.monthRef}>
                          <option value="">--- Select ---</option>
                          <option value="1">Januray</option>
                          <option value="2">February</option>
                          <option value="3">March</option>
                          <option value="4">April</option>
                          <option value="5">May</option>
                          <option value="6">June</option>
                          <option value="7">July</option>
                          <option value="8">August</option>
                          <option value="9">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </Form.Control>
                      </Form.Group>
                    </div>

                    <div className="col-sm-6">
                      <Form.Group controlId="maker">
                        <Form.Label>Maker</Form.Label>
                        <Form.Control as="select" ref={this.makerRef} onChange={(e) => this.getModel(e.target.value)}>
                          <option value="">-- Select --</option>
                          {this.state.maker.map((place) => (
                            <option value={place.id}>{place.maker}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </div>

                    <div className="col-sm-6">
                      <Form.Group controlId="maker">
                        <Form.Label>Model</Form.Label>
                        <Form.Control as="select" ref={this.modelRef} onChange={(e) => this.getGenAndVar(e.target.value)}>
                          <option value="">-- Select --</option>
                          {this.state.model.map((place) => (
                            <option value={place.id}>{place.model}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </div>

                    <div className="col-sm-6">
                      <Form.Group controlId="maker">
                        <Form.Label>Generation</Form.Label>
                        <Form.Control as="select" ref={this.generationRef}>
                          <option value="">-- Select --</option>
                          {this.state.generation.map((place) => (
                            <option value={place.id}>{place.generation}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </div>

                    <div className="col-sm-6">
                      <Form.Group controlId="variation">
                        <Form.Label>Variation</Form.Label>
                        <Form.Control as="select" ref={this.variationRef}>
                          <option value="">-- Select --</option>
                          {this.state.variation.map((place) => (
                            <option value={place.value}>{place.label}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </div>

                    <div className="col-sm-6">
                      <Form.Group controlId="Price">
                        <Form.Label>Price</Form.Label>
                        <Form.Control type="text" ref={this.priceRef} placeholder="Enter Price" />
                      </Form.Group>
                    </div>

                    <div className="col-sm-6">
                      <Form.Group controlId="Launch Date">
                        <Form.Label>Launch Date</Form.Label>
                        <Form.Control type="date" ref={this.launchdateRef} />
                      </Form.Group>
                    </div>

                    <div className="col-sm-12 mt-3">
                      <Form.Label htmlFor="basic-url">Images</Form.Label>
                      <RUG
                        accept={["jpg", "jpeg", "png", "webp", "gif", "jfif"]}
                        rules={{ size: 5000 }}
                        autoUpload={false}
                        onChange={(images) => {
                          this.setState({ images }); // save current component
                        }}
                      />
                    </div>

                    <div className="col-sm-12 mt-5">
                      <Form.Label htmlFor="basic-url">Description</Form.Label>
                      <Editor
                        apiKey="zpa6jhahb7wr51wcc4yrbt91xeuizav1kudmrtpziohibpz4"
                        init={{
                          plugins: "link image code",
                          toolbar: "undo redo | bold italic | alignleft aligncenter alignright | code",
                        }}
                        onChange={this.handleEditorChange}
                      />
                    </div>

                    <div className="col-sm-3 mt-5">
                      <Form.Group controlId="checkbox">
                        <Form.Check type="checkbox" label="Featured Car" ref={this.featuredRef} />
                      </Form.Group>
                    </div>

                    <div className="col-sm-3 mt-5">
                      <Form.Group controlId="checkbox1">
                        <Form.Check type="checkbox" label="Upcoming" ref={this.upcomingRef} />
                      </Form.Group>
                    </div>

                    <div className="col-sm-3 mt-5">
                      <Form.Group controlId="checkbox2">
                        <Form.Check type="checkbox" label="Expired" ref={this.expiredRef} />
                      </Form.Group>
                    </div>

                    <div className="col-sm-3 mt-5">
                      <Form.Group controlId="checkbox3">
                        <Form.Check type="checkbox" label="Popular" ref={this.popularRef} />
                      </Form.Group>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="advance" title="Advance">
                  <div className="row pt-5">
                    <div className="col-sm-6">
                      <Form.Group>
                        <Form.Label>Avg. Milleage</Form.Label>
                        <Form.Control type="text" ref={this.avgMilRef} />
                      </Form.Group>
                    </div>

                    <div className="col-sm-6">
                      <Form.Group>
                        <Form.Label>Assembly Type</Form.Label>
                        <Form.Control as="select" ref={this.assTypRef}>
                          <option value="">--- Select ---</option>
                          <option>Local</option>
                          <option>Imported</option>
                        </Form.Control>
                      </Form.Group>
                    </div>

                    <div className="col-sm-6">
                      <Form.Group>
                        <Form.Label>Transmission</Form.Label>
                        <Form.Control as="select" ref={this.transRef}>
                          <option value="">--- Select ---</option>
                          <option>Manual</option>
                          <option>Automatic</option>
                        </Form.Control>
                      </Form.Group>
                    </div>

                    <div className="col-sm-6">
                      <Form.Group>
                        <Form.Label>Engine Size</Form.Label>
                        <Form.Control type="text" ref={this.engSizRef} />
                      </Form.Group>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="specifications" title="Specifications">
                  {this.state.specifications &&
                    this.state.specifications.map((specs) => (
                      <div className="py-4">
                        <h5>{specs.category}</h5>
                        <Row>
                          {JSON.parse("[" + specs.specifications + "]").map((spec) => (
                            <Col sm={6}>
                              <input type="hidden" name="s_id[]" value={spec.id} />
                              <Form.Group controlId="checkbox">
                                <Form.Label>{spec.specifications}</Form.Label>
                                {spec.options == "true" ? (
                                  <select name="specifications[]" className="form-control form-select">
                                    {this.state.specOptions.map((option, idx) => option.id == spec.id && option.option.map((op, idx) => <option>{op}</option>))}
                                  </select>
                                ) : (
                                  <Form.Control type="input" name="specifications[]" id={spec.id} />
                                )}
                              </Form.Group>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ))}
                </Tab>

                <Tab eventKey="features" title="Features">
                  <Row className="pt-5">
                    {this.state.features.map((item) => (
                      <input type="hidden" name="f_id[]" value={item.id} />
                    ))}
                    {this.state.features.map((item) => (
                      <Col sm={3}>
                        <Form.Group controlId="checkbox">
                          <Form.Check type="checkbox" label={item.features} name="features[]" id={item.id} value="1" />
                        </Form.Group>
                      </Col>
                    ))}
                  </Row>
                </Tab>
              </Tabs>
            </div>
          </div>

          <div className="card mt-2">
            <div className="card-body">
              <div className="col-sm-6">
                <Form.Group controlId="submit">
                  <Form.Label>Submit</Form.Label>
                  <Button disabled={this.state.disabled} variant="primary" type="submit" size="lg" block>
                    Submit
                  </Button>
                </Form.Group>
              </div>
            </div>
          </div>
          <ToastContainer autoClose={3000} />
        </Form>
      </>
    );
  }
}
