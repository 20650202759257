import React, { Component } from "react";
import Axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Modal } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

export class Comparison extends Component {
  //DELETE FUNCTION
  delete = id => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL + "/comparison/" + id
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getComparisonData();
    });
  };

  //Edit FUNCTION
  edit = id => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/comparison/" + id
    }).then(res => {
      this.setState({
        id: id,
        einventory: res.data[0].inventory,
        einventory1: res.data[0].inventory1
      });
    });
    this.handleShow();
  };

  eSubmit = (event, id) => {
    event.preventDefault();
    Axios({
      method: "put",
      url: process.env.REACT_APP_API_URL + "/comparison/" + this.state.id,
      data: {
        einventory: this.state.einventory,
        einventory1: this.state.einventory1
      }
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getComparisonData();
    });
    this.handleClose();
  };
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  // Submit Trigger
  submit = event => {
    event.preventDefault();
    Axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/comparison",
      data: {
        inventory: this.inventoryRef.current.value,
        inventory1: this.inventory1Ref.current.value
      }
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getComparisonData();
    });
  };

  //Get all Inventories
  async getInventoryData() {
    const result = await Axios.get(
      process.env.REACT_APP_API_URL + "/inventory"
    );
    this.setState({ inventory: result.data });
  }

  async getComparisonData() {
    const result = await Axios.get(
      process.env.REACT_APP_API_URL + "/comparison"
    );
    this.setState({ data: result.data });
  }

  componentDidMount() {
    this.getInventoryData();
    this.getComparisonData();
  }

  // Filter Table
  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return (
        value.inventory?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.inventory1?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.status?.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      inventory: [],
      inventory1: [],
      searchInput: "",

      // Edit
      show: false,
      id: "",
      einventory: "",
      einventory1: ""
    };
    this.inventoryRef = React.createRef(null);
    this.inventory1Ref = React.createRef(null);
  }

  render() {
    const columns = [
      { Header: "Inventory 1", accessor: "inventory" },
      { Header: "Inventory 2", accessor: "inventory1" },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <FontAwesomeIcon
              className="text-danger mr-3"
              icon={faTrash}
              onClick={() => this.delete(row.id)}
            />
            <FontAwesomeIcon
              className="text-info ml-3"
              onClick={() => this.edit(row.id)}
              icon={faPencilAlt}
            />
          </div>
        )
      }
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
            <Form onSubmit={this.submit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="inventory">
                    <Form.Label>Inventory 1</Form.Label>
                    <Form.Control as="select" ref={this.inventoryRef}>
                      <option value="">-- Select --</option>
                      {this.state.inventory.map(place => (
                        <option value={place.id}>
                          {place.maker} {place.model} {place.variation}{" "}
                          {place.year}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="inventory1">
                    <Form.Label>Inventory 2</Form.Label>
                    <Form.Control as="select" ref={this.inventory1Ref}>
                      <option value="">-- Select --</option>
                      {this.state.inventory.map(place => (
                        <option value={place.id}>
                          {place.maker} {place.model} {place.variation}{" "}
                          {place.year}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <label>Search: </label>
              <input
                name="searchInput"
                onChange={this.handleChange}
                label="Search"
                className="ml-3"
              />
            </div>
            <ReactTable
              data={
                this.state.filteredData && this.state.filteredData.length
                  ? this.state.filteredData
                  : this.state.data
              }
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </div>

        {/*here its edited modal*/}
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Body>
            <Form onSubmit={this.eSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="inventory">
                    <Form.Label>Inventory 1</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e =>
                        this.setState({ einventory: e.target.value })
                      }
                    >
                      <option value="">-- Select --</option>
                      {this.state.inventory.map(place => (
                        <option value={place.id}>
                          {place.maker} {place.model} {place.variation}{" "}
                          {place.year}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="inventory1">
                    <Form.Label>Inventory 2</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e =>
                        this.setState({ einventory1: e.target.value })
                      }
                    >
                      <option value="">-- Select --</option>
                      {this.state.inventory.map(place => (
                        <option value={place.id}>
                          {place.maker} {place.model} {place.variation}{" "}
                          {place.year}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="close">
                    <Form.Label>Close</Form.Label>
                    <Button
                      variant="danger"
                      type="button"
                      size="lg"
                      block
                      onClick={this.handleClose}
                    >
                      Close
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
