import React, { Component, useState } from "react";
import Axios from "axios";
import "./Dashboard.css";

const Collpase = ({ title, open = false, children }) => {
  const [collapse, setCollapse] = useState(open);

  return (
    <div className="card mb-3">
      <div className="card-body">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setCollapse(!collapse)}
          className="d-flex justify-content-between"
        >
          <h4>{title}</h4>
          {collapse ? <h4>x</h4> : <h4>+</h4>}
        </div>
        {collapse && <div className="mt-4">{children}</div>}
      </div>
    </div>
  );
};

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      all: [],
      categories: [],
      pending: [],
      deleted: []
    };
  }

  componentDidMount() {
    this.allStat();
    this.getCategories();
    this.getCategoriesPending();
    this.getCategoriesDeleted();
  }

  allStat() {
    Axios.get(`${process.env.REACT_APP_API_URL}/stat/all`).then(res => {
      this.setState({
        all: res.data
      });
    });
  }
  getCategories() {
    Axios.get(`${process.env.REACT_APP_API_URL}/category`).then(res => {
      this.setState({
        categories: res.data
      });
    });
  }
  getCategoriesPending() {
    Axios.get(`${process.env.REACT_APP_API_URL}/stat/pending`).then(res => {
      this.setState({
        pending: res.data
      });
    });
  }
  getCategoriesDeleted() {
    Axios.get(`${process.env.REACT_APP_API_URL}/stat/deleted`).then(res => {
      this.setState({
        deleted: res.data
      });
    });
  }

  render() {
    const { all, categories, pending, deleted } = this.state;

    return (
      <div className="container">
        <Collpase title="Auction Sheet Stat" open={true}>
          <div className="d-flex justify-content-around">
            {all &&
              all.map(
                (item, j) =>
                  item.label == "AS" && (
                    <div className="mt-4 rounded border p-5" key={j}>
                      <div className="text-center">
                        <h3 className="font-weight-bold mb-2">{item.total}</h3>
                        <h6 className="font-weight-light text-secondary">
                          {item.value}
                        </h6>
                      </div>
                    </div>
                  )
              )}
          </div>
        </Collpase>
        <Collpase title="Package Stat" open={true}>
          <div className="d-flex justify-content-around">
            {all &&
              all.map(
                (item, j) =>
                  item.label == "Payments" && (
                    <div className="mt-4 rounded border p-5" key={j}>
                      <div className="text-center">
                        <h3 className="font-weight-bold mb-2">{item.total}</h3>
                        <h6 className="font-weight-light text-secondary">
                          {item.value}
                        </h6>
                      </div>
                    </div>
                  )
              )}
          </div>
        </Collpase>
        <Collpase title="Active Stat" open={true}>
          <div className="row">
            {categories &&
              categories.map((category, i) => (
                <div className="col-sm-3 mb-4" key={i}>
                  <div className="card">
                    <div className="card-body position-relative">
                      <h6>{category.category}</h6>
                      <h4 className="font-weight-bold mb-0">
                        {category.total}
                      </h4>
                      <img
                        className="position-absolute"
                        style={{
                          bottom: "20px",
                          right: "20px",
                          height: "50px",
                          opacity: "0.75"
                        }}
                        src={`${process.env.REACT_APP_AWS_URL}${category.logo}`}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </Collpase>
        <Collpase title="Pending Stat" open={true}>
          <div className="row">
            {pending &&
              pending.map((category, j) => (
                <div className="col-sm-3 mb-4" key={j}>
                  <div className="card">
                    <div className="card-body position-relative">
                      <h6>{category.category}</h6>
                      <h4 className="font-weight-bold mb-0">
                        {category.total}
                      </h4>
                      <img
                        className="position-absolute"
                        style={{
                          bottom: "20px",
                          right: "20px",
                          height: "50px",
                          opacity: "0.75"
                        }}
                        src={`${process.env.REACT_APP_AWS_URL}${category.logo}`}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </Collpase>
        <Collpase title="User Stat">
          <div className="row">
            {all &&
              all.map(
                (item, i) =>
                  item.label == "Users" && (
                    <div className="col-sm-3 mb-4" key={i}>
                      <div className="card">
                        <div className="card-body position-relative">
                          <h6>{item.value}</h6>
                          <h4 className="font-weight-bold mb-0">
                            {item.total}
                          </h4>
                        </div>
                      </div>
                    </div>
                  )
              )}
          </div>
        </Collpase>
        <Collpase title="Location Stat">
          <div className="row">
            {all &&
              all.map(
                (item, i) =>
                  item.label == "Location" && (
                    <div className="col-sm-3 mb-4" key={i}>
                      <div className="card">
                        <div className="card-body position-relative">
                          <h6>{item.value}</h6>
                          <h4 className="font-weight-bold mb-0">
                            {item.total}
                          </h4>
                        </div>
                      </div>
                    </div>
                  )
              )}
          </div>
        </Collpase>
        {categories &&
          categories.map(
            (category, i) =>
              category.category != "Parts" &&
              category.category != "Car Rental" && (
                <Collpase title={category.category + " Maker & Models Stat"}>
                  <div className="row" key={i}>
                    {all &&
                      all.map(
                        (item, j) =>
                          item.value == category.category && (
                            <div className="col-sm-3 mb-4" key={j}>
                              <div className="card">
                                <div className="card-body position-relative">
                                  <h6>{item.label}</h6>
                                  <h4 className="font-weight-bold mb-0">
                                    {item.total}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          )
                      )}
                  </div>
                </Collpase>
              )
          )}
        <Collpase title="Deleted Stat">
          <div className="row">
            {deleted &&
              deleted.map((category, j) => (
                <div className="col-sm-3 mb-4" key={j}>
                  <div className="card">
                    <div className="card-body position-relative">
                      <h6>{category.category}</h6>
                      <h4 className="font-weight-bold mb-0">
                        {category.total}
                      </h4>
                      <img
                        className="position-absolute"
                        style={{
                          bottom: "20px",
                          right: "20px",
                          height: "50px",
                          opacity: "0.75"
                        }}
                        src={`${process.env.REACT_APP_AWS_URL}${category.logo}`}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </Collpase>
      </div>
    );
  }
}
