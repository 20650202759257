import React, { Component } from 'react'
import Axios from 'axios'

import ReactTable from "react-table-v6"
import 'react-table-v6/react-table.css'

import { Button, Form, Col, Modal, Image } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Bodytype extends Component {

  //DELETE FUNCTION
  delete = (id) => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL+'/bodytype/'+id,
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getNameData()
    })
  }

  //Edit FUNCTION
  edit = (id) => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL+'/bodytype/'+id,
    }).then(res => {
      this.setState({
        id: id,
        logo: res.data[0].logo,
        ctr_id: res.data[0].ctr_id,
        ecategory: res.data[0].category,
        name: res.data[0].name
      })
    })
    this.handleShow()
  }

  eSubmit = (event, id) => {
    event.preventDefault()
    var formData = new FormData()
    formData.append('category', this.state.ctr_id)
    formData.append('name', this.state.name)
    formData.append('image_name', this.state.logo)
    if (this.state.imageData.length !== 0)
      formData.append('logo', this.state.imageData, this.state.imageData.name)
    Axios({
      method: 'put',
      url: process.env.REACT_APP_API_URL+'/bodytype/'+this.state.id,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getNameData()
    })
    this.handleClose()
  }

  handleClose = () => this.setState({show: false});
  handleShow = () => this.setState({show: true});

  // Submit Trigger
  submit = (event) => {
    event.preventDefault()
    var formData = new FormData()
    formData.append('category', this.categoryRef.current.value)
    formData.append('name', this.nameRef.current.value)
    if (this.state.imageData.length !== 0)
      formData.append('logo', this.state.imageData, this.state.imageData.name)
    Axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL+'/bodytype',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      this.getNameData()
    })
  }

  async getCategoryData(){
      const result = await Axios.get(process.env.REACT_APP_API_URL+'/category');
      this.setState({category: result.data});
  }

  async getNameData(){
      const result = await Axios.get(process.env.REACT_APP_API_URL+'/bodytype');
      this.setState({data: result.data});
    }

  componentDidMount(){
    this.getCategoryData()
    this.getNameData()
  }

  // Filter Table
  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch()
    });
  };
  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return (
          value.category.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.name.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    this.setState({ filteredData });
  };

  handleChangeBody = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearchCategory()
    });
  };

  globalSearchCategory = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return (
          value.category.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    const nullData = [{'id': 'null', 'category': 'null', 'name': 'null', 'status': 'null'}]
    const searchData = filteredData.length ? filteredData : nullData
    this.setState({ 'filteredData' : searchData });
  };

  constructor(props){
    super(props)
    this.state = {
      data: [],
      filteredData: [],
      category: [],
       Data: [],
      searchInput: "",
      fileURL: '',
      logo: '',
      imageData: [],

      // Edit
      show: false,
      id: '',
      ctr_id: '',
      ecategory: [''],
      name:[''],
    }
    this.categoryRef = React.createRef(null)
    this.nameRef = React.createRef(null)
  }

  render() {

    const columns = [
      { Header: 'logo', accessor: 'logo',
        Cell: ({row}) => (
          <img src={`${process.env.REACT_APP_AWS_URL}${row.logo}`} height="30px" width="30px" />
        )
      },
      { Header: 'Category', accessor: 'category' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Actions' , accessor:'id',
        Cell: ({row}) => (
          <div>
             <FontAwesomeIcon className="text-danger mr-3" icon={faTrash} onClick={() => this.delete(row.id)} />
             <FontAwesomeIcon className="text-info ml-3" onClick={() => this.edit(row.id)} icon={faPencilAlt} />
          </div>
        )
      }
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
          <Form onSubmit={this.submit}>
            <div className="row">
            <div className="col-sm-6">
            <Form.Group controlId="Body category">
              <Form.Label>Category</Form.Label>
              <Form.Control as="select" ref={this.categoryRef} >
                <option value="">-- Select --</option>
                {this.state.category.map((place) => (
                  <option value={place.id}>{place.category}</option>
                ))}
              </Form.Control>
            </Form.Group>
            </div>
            <div className="col-sm-6">
              <Form.Group controlId="Name">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" ref={this.nameRef} placeholder="Name" />
              </Form.Group>
            </div>

            <div className="col-sm-6">
              <Form.Group controlId="image">
                <Form.Label>Image</Form.Label>
                  <Form.File label="Body Logo" custom type="file" accept="image/*"
                    onChange={(e) => this.setState({imageData: e.target.files[0], fileURL: URL.createObjectURL(e.target.files[0])})}
                  />
              </Form.Group>
            </div>

              <div className="col-sm-6">
                <Form.Group controlId="submit">
                <Form.Label>Submit</Form.Label>
                <Button variant="primary" type="submit" size="lg" block>
                  Submit
                </Button>
                </Form.Group>
              </div>
            </div>
          </Form>

          <div>
            <Image src={this.state.fileURL} height="100px" width="auto"/>
          </div>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <div className="col-sm-6" onChange={this.handleChangeBody} >
                <Form.Group controlId="Body category">
                  <Form.Label>Category</Form.Label>
                  <Form.Control as="select" >
                    <option value="">-- Select --</option>
                    {this.state.category.map((place) => (
                      <option value={place.category}>{place.category}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <label>Search: </label>
              <input
                name="searchInput"
                onChange={this.handleChange}
                label="Search"
                className="ml-3"
              />
            </div>
            <ReactTable
               data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : this.state.data}
               columns={columns}
               defaultPageSize={10}
            />
          </div>
        </div>

        {/*here its edited modal*/}
        <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Body>
              <Form onSubmit={this.eSubmit}>
                <input type="hidden" name="image_name" value={this.state.logo} />
                <div className="row">
                  <div className="col-sm-6">
                    <Form.Group controlId="Body category">
                      <Form.Label>Category</Form.Label>
                      <Form.Control as="select" onChange={(e) => this.setState({ctr_id: e.target.value})}  value={this.state.ctr_id} >
                        <optgroup label="Selected">
                          <option value={this.state.ctr_id}>{this.state.ecategory}</option>
                        </optgroup>
                        <optgroup label="Options">
                          {this.state.category.map((ecategory) => (
                            <option value={ecategory.id}>{ecategory.category}</option>
                          ))}
                        </optgroup>
                      </Form.Control>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="Name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" onChange={(e) => this.setState({name: e.target.value})}  value={this.state.name} placeholder="Name" />
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="image">
                      <Form.Label>Image</Form.Label>
                        <Form.File label="Body Logo" custom type="file" accept="image/*"
                          onChange={(e) => this.setState({imageData: e.target.files[0]})}
                        />
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="close">
                      <Form.Label>Close</Form.Label>
                      <Button variant="danger" type="button" size="lg" block onClick={this.handleClose}>
                        Close
                      </Button>
                    </Form.Group>
                  </div>
                </div>
              </Form>
            </Modal.Body>
        </Modal>
        <ToastContainer autoClose={3000} />
      </>
    );

  }

}
