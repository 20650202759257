import React, { Component } from "react";
import Axios from "axios";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

import { Button, Form, Col, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class City extends Component {
  //DELETE FUNCTION
  delete = id => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL + "/city/" + id
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getCityData();
    });
  };

  //Edit FUNCTION
  edit = id => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/city/" + id
    }).then(res => {
      this.setState({
        id: id,
        ctr_id: res.data[0].ctr_id,
        state_id: res.data[0].state_id,
        ecountry: res.data[0].country,
        estate: res.data[0].state,
        city: res.data[0].city
      });
    });
    this.handleShow();
  };

  eSubmit = (event, id) => {
    event.preventDefault();
    Axios({
      method: "put",
      url: process.env.REACT_APP_API_URL + "/city/" + this.state.id,
      data: {
        country: this.state.ctr_id,
        city: this.state.city,
        state: this.state.state_id
      }
    }).then(res => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getCityData();
    });
    this.handleClose();
  };
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  // Submit Trigger
  submit = event => {
    event.preventDefault();
    Axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/city",
      data: {
        country: this.countryRef.current.value,
        city: this.cityRef.current.value,
        state: this.stateRef.current.value
      }
    }).then(res => {
      console.log(res.data.errno);
      if (res.data.errno) {
        toast.warn("Not Submitted");
      } else {
        toast.success(res.data.message);
      }
      this.getCityData();
    });
  };

  // Display Trigger
  async getCityData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/city");
    this.setState({ data: result.data });
  }

  //Get all countries
  async getCountryData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/country");
    this.setState({ country: result.data });
  }
  //Get all states
  async getStateData() {
    const result = await Axios.get(process.env.REACT_APP_API_URL + "/state");
    this.setState({ state: result.data });
  }

  componentDidMount() {
    this.getCityData();
    this.getCountryData();
    this.getStateData();
  }

  // Filter Table
  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };
  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter(value => {
      return (
        value.country.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.city.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.state.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.status.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      country: [],
      state: [],
      searchInput: "",

      // Edit
      show: false,
      id: "",
      ctr_id: "",
      state_id: "",
      ecountry: [],
      estate: [],
      city: []
    };
    this.countryRef = React.createRef(null);
    this.cityRef = React.createRef(null);
    this.stateRef = React.createRef(null);
  }

  render() {
    const columns = [
      { Header: "Country", accessor: "country" },
      { Header: "City", accessor: "city" },
      { Header: "State", accessor: "state" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <FontAwesomeIcon
              className="text-danger mr-3"
              icon={faTrash}
              onClick={() => this.delete(row.id)}
            />
            <FontAwesomeIcon
              className="text-info ml-3"
              onClick={() => this.edit(row.id)}
              icon={faPencilAlt}
            />
          </div>
        )
      }
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
            <Form onSubmit={this.submit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="country">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      as="select"
                      ref={this.countryRef}
                      required={true}
                    >
                      <option value="">-- Select --</option>
                      {this.state.country.map(place => (
                        <option value={place.id}>{place.country}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="state">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      as="select"
                      ref={this.stateRef}
                      required={true}
                    >
                      <option value="">-- Select --</option>
                      {this.state.state.map(place => (
                        <option value={place.id}>{place.state}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="city">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      ref={this.cityRef}
                      placeholder="City"
                      required={true}
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <label>Search: </label>
              <input
                name="searchInput"
                onChange={this.handleChange}
                label="Search"
                className="ml-3"
              />
            </div>
            <ReactTable
              data={
                this.state.filteredData && this.state.filteredData.length
                  ? this.state.filteredData
                  : this.state.data
              }
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </div>

        {/*here its edited modal */}
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Body>
            <Form onSubmit={this.eSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="country">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => {
                        this.setState({ ctr_id: e.target.value });
                      }}
                      value={this.state.ctr_id}
                    >
                      <optgroup label="Selected">
                        <option value={this.state.ctr_id}>
                          {this.state.ecountry}
                        </option>
                      </optgroup>
                      <optgroup label="Options">
                        {this.state.country.map(ecountry => (
                          <option value={ecountry.id}>
                            {ecountry.country}
                          </option>
                        ))}
                      </optgroup>
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="state">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => {
                        this.setState({ state_id: e.target.value });
                      }}
                    >
                      <optgroup label="Selected">
                        <option value={this.state.state_id}>
                          {this.state.estate}
                        </option>
                      </optgroup>
                      <optgroup label="Options">
                        {this.state.state.map(place => (
                          <option value={place.id}>{place.state}</option>
                        ))}
                      </optgroup>
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="city">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => this.setState({ city: e.target.value })}
                      value={this.state.city}
                      placeholder="City"
                    />
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="close">
                    <Form.Label>Close</Form.Label>
                    <Button
                      variant="danger"
                      type="button"
                      size="lg"
                      block
                      onClick={this.handleClose}
                    >
                      Close
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
